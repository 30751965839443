import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type RowAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type RowActionsProps = {
    actions: Array<RowAction>,
    small?: boolean,
};

export default function RowActions({ actions, small }: RowActionsProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setHighlightedIndex(-1);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.preventDefault();
        if (event.key === "ArrowDown") {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % actions.length);
        } else if (event.key === "ArrowUp") {
            if (highlightedIndex === -1) { setHighlightedIndex(actions.length); }
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + actions.length) % actions.length);
        } else if (event.key === "Enter" && highlightedIndex >= 0) {
            actions[highlightedIndex].onClick();
            setTimeout(() => {
                handleClose(); 
            }, 100);
        } else if (event.key === "Escape" || event.key === "Tab") {
            handleClose();
        }
    };

    return (
        <>
            <Button
                style={{ border: '1px solid #D7E3F2' }}
                className={`text-black py-1 overflow-hidden rounded-[0.4rem] ${open ? 'bg-gray-300' : ''}`}
                onClick={handleClick}
                sx={{
                    ml: 0,
                    mr: 0,
                    ...(open && {
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
                            width: 10,
                            height: 10,
                            bgcolor: 'white',
                            border: '1px solid #D7E3F2',
                        },
                    }),
                }}
            >
                <div className={`${small ? '' : 'px-6'} py-1 text-black`}>
                    {!small ? t('datagrid:actions') : null}
                    <FontAwesomeIcon className={!small ? 'pl-2' : ''} icon={faEllipsis} />
                </div>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: '0.4rem',
                        border: '1px solid #D7E3F2',
                        zIndex: 10,
                    },
                }}
                onKeyDown={handleKeyDown}
                autoFocus={false}
            >
                {actions.map((action, index) => (
                    <MenuItem
                        key={action.label}
                        onClick={() => action.onClick()}
                        className={`h-8 px-5 my-1 ${highlightedIndex === index ? 'bg-blue-50' : ''}`}
                    >
                        <div className="flex items-center w-full border-b-[1px] border-[#dbdbdb]">
                            {action.icon && (
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    {action.icon}
                                </ListItemIcon>
                            )}
                            <ListItemText className="py-1 px-1">
                                {action.label}
                            </ListItemText>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
