import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

export interface DropdownProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label: string,
    sublabel?: string,
    initial?: string,
    required?: boolean,
    options: Record<string, string | number>,
    onChange?: (value: string) => void,
    action?: () => void,
    onOpen?: () => void,
    value?: string,
    id?: string,
    up?: boolean,
    noMarginBottom?: boolean,
    onFocus?: () => void
}

const Dropdown = (props: DropdownProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const defaultRef = useRef<HTMLDivElement>(null);
    const { label, sublabel, className, onOpen, required, up, onChange: onChangeOut, action, initial, options, id, value: selectedValue, noMarginBottom, ...restProps } = props
    const { t } = useTranslation()
    const labelOrId = id || label

    const [value, setValue] = useState(initial || selectedValue || '');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

    useEffect(() => {
        if (!options) return;
        if (!initial) return;

        if (value !== initial) {
            setValue(initial);
        }
    }, [initial, selectedValue, options])

    useEffect(() => {
        const newFilteredOptions = Object.fromEntries(
            Object.entries(options).filter(([k, v]) =>
                String(v).toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredOptions(newFilteredOptions);

        // Adjust highlightedIndex if the current highlighted item is filtered out
        const optionKeys = Object.keys(newFilteredOptions);
        if (highlightedIndex !== null && highlightedIndex >= optionKeys.length) {
            setHighlightedIndex(optionKeys.length > 0 ? optionKeys.length - 1 : null);
        }
    }, [searchTerm]);

    useEffect(() => {
        const checkIfClickedOutside = (e: { target: any }) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    })

    const onChange = (item: string) => {
        setIsOpen(false);
        setValue(item);
        setHighlightedIndex(null);
        if (onChangeOut) onChangeOut(item);
    }

    const doChangeOpen = (value: boolean) => {
        if (value && onOpen) onOpen();

        setIsOpen(value);
        if (isOpen === false) {
            setSearchTerm('');
            setHighlightedIndex(null);
        }
    }

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, [isOpen]);

    useEffect(() => {
        if (listRef.current && highlightedIndex !== null) {
            const optionElements = listRef.current.children;
            if (optionElements[highlightedIndex]) {
                optionElements[highlightedIndex + 1].scrollIntoView({ block: 'end' });
            }
        }
    }, [highlightedIndex]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const optionKeys = Object.keys(filteredOptions);

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prev) => {
                if (prev === null || prev === optionKeys.length - 1) return 0;
                return prev + 1;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prev) => {
                if (prev === null || prev === 0) return optionKeys.length - 1;
                return prev - 1;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightedIndex !== null) {
                onChange(optionKeys[highlightedIndex]);
            }
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        }  else if (e.key === 'Tab') {
            if (!isOpen) return;
            e.preventDefault();
            setIsOpen(false);
            if (defaultRef.current) {
                defaultRef.current.focus();
            }
        }
    };

    return (
        <div {...restProps} ref={ref} className={`flex flex-col z-40 ${!noMarginBottom && 'mb-6'} ${className}`} onKeyDown={Object.entries(filteredOptions).length > 0 ? handleKeyDown : undefined}>
            <label className='' htmlFor={labelOrId}>{label}{required && <span className='text-red-400'>*</span>} {sublabel && <span className='opacity-70 ext-sm'>({sublabel})</span>}</label>
            <div className='flex flex-grow mt-1 relative'>
                <div onClick={() => doChangeOpen(!isOpen)} onKeyDown={(e) => e.key === 'Enter' && doChangeOpen(!isOpen)} tabIndex={0} ref={defaultRef} className='flex items-center cursor-pointer rounded-[0.4rem] border-[1px] border-black border-opacity-10 flex-grow px-2 pl-3 py-2 max-w-full'>
                    <p className='whitespace-nowrap overflow-hidden overflow-ellipsis w-[95%]'>{options[value] || '\u00a0'}</p>
                    
                    <FontAwesomeIcon className={`text-sm ml-auto mr-1 ${up ? (isOpen ? '' : 'rotate-180') : (isOpen ? 'rotate-180' : '')}`} icon={faChevronDown} />
                </div>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            ref={listRef}
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                            transition={{ type: "smooth", duration: 0.2 }}
                            className={`absolute ${up ? 'bottom-11' : 'mt-11'} rounded-[0.4rem] border-[2px] border-black border-opacity-10 w-full overflow-y-auto max-h-48 flex flex-col bg-light-200 z-40`}>
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder={t('sidebar:search') || ''}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => {
                                    if (['Enter', 'ArrowUp', 'ArrowDown', 'Escape'].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                className="border-b-[2px] border-black border-opacity-10 p-2 focus:outline-none"
                            />

                            {Object.entries(filteredOptions).length > 0 ? (
                                Object.entries(filteredOptions).map(([k, v], i) => (
                                    <div
                                        key={i}
                                        onClick={() => onChange(k)}
                                        className={`flex flex-grow first:pt-4 p-1 px-4 cursor-pointer transition-colors hover:bg-blue-500 hover:text-white ${k === value ? 'bg-blue-200' : ''} ${highlightedIndex === i ? 'bg-blue-300' : ''}`}>
                                        {v}
                                    </div>
                                ))
                            ) : (
                                <div className="flex flex-grow p-4 text-center text-gray-500">{t('noResult')}</div>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>

            </div>
            <input value={value} type='hidden' name={labelOrId} id={labelOrId} />
            <input name={'@VALID@' + labelOrId} type="hidden" value={(value.length > 0) + ''} />
            {<motion.div initial={{ height: '0rem' }} animate={(value.length > 0 || !required) ? { height: '0rem' } : { height: '1.2rem' }} className='text-sm overflow-hidden text-red-400 mb-2'>{(value.length <= 0 && t('required')) || '\u00a0'}</motion.div>}
        </div>
    )
}

export default Dropdown;
