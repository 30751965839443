import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface PillProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text: string,
    onRemove?: () => void
}

const Pill = (props: PillProps) => {

    const { text, onRemove, ...restProps } = props

    return (
        <div {...restProps} className='flex py-1 px-4 rounded-full items-center justify-center border-light-300 border-2 gap-3'>
            <span>{ text }</span>
            { onRemove && <div onClick={ onRemove } onKeyDown={(e) => e.key === 'Enter' && onRemove() } tabIndex={0} className='flex cursor-pointer items-center justify-center'>
                <FontAwesomeIcon size='xs' icon={ faXmark } />
            </div> }
        </div>
    )
}

export default Pill