import { motion } from 'framer-motion';
import React from 'react';
import wave from '../../Assets/wave.svg';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text: string;
    subtitle?: string;
    textNotBold?: boolean;
    icon?: IconDefinition;
    hasWave?: boolean;
    noHyphens?: boolean;
    center?: boolean;
}

const Title = (props: TitleProps) => {
    const { text, subtitle, className, textNotBold, icon, hasWave, noHyphens, center, ...restProps } = props;
    const [isWaveCooldown, setIsWaveCooldown] = React.useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setIsWaveCooldown(true);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div
            {...restProps}
            style={{ fontSize: '40px' }}
            className={`mb-5 ${textNotBold ? '' : 'font-bold'} ${textNotBold ? 'font-normal text-4xl' : 'font-title text-2xl'} ${center && 'text-center'} ${className}`}
        >
            <div className='mb-1'>
                <motion.div
                    transition={{ duration: 0.5 }}
                    animate={{ translateY: ['1.5em', '0em'], opacity: [0, 1] }}
                    style={{
                        wordBreak: noHyphens ? 'keep-all' : 'break-word',
                        hyphens: noHyphens ? 'none' : 'auto',
                    }}
                    className={`flex items-center ${center && 'justify-center'}`}
                >
                    {icon && <FontAwesomeIcon icon={icon} className='mr-2' />}
                    {text}
                    {hasWave && (
                        <img
                            src={wave}
                            alt="Wave"
                            className={`w-11 h-11 ${isWaveCooldown && 'wave-animation'}`}
                        />
                    )}
                </motion.div>
            </div>
            {subtitle && (
                <div
                    style={{
                        fontSize: '16px',
                        wordBreak: noHyphens ? 'keep-all' : 'break-word',
                        hyphens: noHyphens ? 'none' : 'auto',
                    }}
                    className={`overflow-hidden font-normal ${center && 'flex justify-center'}`}
                >
                    <motion.div
                        transition={{ duration: 0.5, delay: 0.15 }}
                        animate={{ translateY: ['-1.5em', '0em'], opacity: [0, 1] }}
                    >
                        {subtitle}
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default Title;
