import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileAlt, faCalendarAlt, faCodeBranch, faNewspaper } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import IconSVG from '../../../../../Assets/icon.svg?component';

interface ReleaseNotePreviewProps {
    type: string;
    version: string;
    title: string;
    content: string;
}

const ReleaseNotePreview: React.FC<ReleaseNotePreviewProps> = ({ type, version, title, content }) => {
    const { t } = useTranslation();

    const [isSectionOpen, setIsSectionOpen] = useState(false);
    const [isNew, setIsNew] = useState(true);

    const date = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isSectionOpen) {
                setIsSectionOpen(false);
            }

            if (event.key === "Tab" && isSectionOpen) {
                event.preventDefault();
                setIsSectionOpen(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isSectionOpen]);

    const parseContent = (content: string) => {
        let skipItem = false;
        return content
            .split('\n')
            .map((line, lineIndex) => {
                skipItem = false;

                const leadingSpacesMatch = line.match(/^\s+/);
                const leadingSpaces = leadingSpacesMatch
                    ? leadingSpacesMatch[0]
                        .split('')
                        .map((_, index) => (
                            <React.Fragment key={`space-${lineIndex}-${index}`}>&nbsp;</React.Fragment>
                        ))
                    : [];

                const filteredLine = line.replace(
                    /\{(sponsor|notify|patrick|scan|family|photo) \((.*?)\)\}/g,
                    (match, type, content) => {
                        const formattedContent = content.replace(/ /g, '\u00A0');

                        if (type === 'sponsor') {
                            return formattedContent;
                        }
                        if (type === 'notify') {
                            return formattedContent;
                        }
                        if (type === 'patrick') {
                            return formattedContent;
                        }
                        if (type === 'scan') {
                            return formattedContent;
                        }
                        if (type === 'family') {
                            return formattedContent;
                        }
                        if (type === 'photo') {
                            return formattedContent;
                        }
                        skipItem = true;
                        return '';
                    }
                );

                if (skipItem) return null;

                if (filteredLine.trim() === '--') {
                    return <hr key={`hr-${lineIndex}`} />;
                }

                const formatText = (text: string) => {
                    const applyFormatting = (text: string) => {
                        const formatMap = [
                            { regex: /\*\*\*([^*]+?)\*\*\*/g, component: (key: string, content: string) => <strong className='text-xl' key={key}>{content}</strong> },
                            { regex: /\*\*([^*]+?)\*\*/g, component: (key: string, content: string) => <strong key={key}>{content}</strong> },
                            { regex: /\*([^*]+?)\*/g, component: (key: string, content: string) => <em key={key}>{content}</em> },
                            { regex: /__([^_]+?)__/g, component: (key: string, content: string) => <u key={key}>{content}</u> },
                        ];

                        let formattedText: (string | JSX.Element)[] = [text];

                        formatMap.forEach(({ regex, component }) => {
                            formattedText = formattedText.flatMap((part, index) => {
                                if (typeof part === 'string') {
                                    const parts: (string | JSX.Element)[] = [];
                                    let match;
                                    let lastIndex = 0;
                                    while ((match = regex.exec(part)) !== null) {
                                        if (match.index > lastIndex) {
                                            parts.push(part.slice(lastIndex, match.index));
                                        }
                                        parts.push(component(`${index}-${match.index}`, match[1]));
                                        lastIndex = match.index + match[0].length;
                                    }
                                    if (lastIndex < part.length) {
                                        parts.push(part.slice(lastIndex));
                                    }
                                    return parts;
                                }
                                return part;
                            });
                        });

                        return formattedText;
                    };

                    const parts = text.split(/(\*\*\*[^*]+?\*\*\*|\*\*[^*]+?\*\*|\*[^*]+?\*|__[^_]+?__)/g);

                    return parts.map((part, index) => (
                        <React.Fragment key={`${lineIndex}-${index}`}>
                            {typeof part === 'string' ? applyFormatting(part) : part}
                        </React.Fragment>
                    ));
                };

                if (filteredLine.trim().startsWith('-')) {
                    const listItemContent = filteredLine.trim().slice(1).trim();
                    return (
                        <li className='ml-5 break-words' key={`li-${lineIndex}`}>
                            {formatText(listItemContent)}
                        </li>
                    );
                }

                if (filteredLine.trim() === '') {
                    return <div key={`line-${lineIndex}`}>&nbsp;</div>;
                }

                return (
                    <div key={`line-${lineIndex}`}>
                        {leadingSpaces}
                        {formatText(filteredLine)}
                    </div>
                );
            });
    };

    return (
        <>
            <div className="relative h-[70px]">
                <div
                    tabIndex={0}
                    className={`absolute h-[56.9px] -ml-5 lg:-ml-10 w-screen lg:w-[calc(100vw-320px)] group pl-4 pr-1.5 flex justify-between items-center text-white cursor-pointer shadow-lg ${isNew ? 'bg-green-500' : 'bg-[#0175FF]'}`}
                    onClick={() => setIsSectionOpen(true)}
                    onKeyDown={(e) => e.key === 'Enter' && setIsSectionOpen(true)}
                >
                    <FontAwesomeIcon icon={type === 'News' ? faNewspaper : faFileAlt} className="text-2xl hidden sm:block" />
                    <div className="flex gap-3 sm:gap-4 group-focus:scale-105 group-hover:scale-105 transition duration-300">
                        {type !== 'News' && (
                            <h3 className="whitespace-nowrap hidden md:block">
                                <strong>{t('releasenote:version')}:</strong> {version}
                            </h3>
                        )}
                        <p className="whitespace-nowrap hidden sm:block">
                            <strong>{t('releasenote:date')}:</strong> {date}
                        </p>
                        <p className="whitespace-nowrap font-bold relative">
                            {parseContent(title)}
                            {isNew &&
                                <span className="absolute top-[-5px] right-[-15px] bg-[#0175FF] text-white text-xs font-bold px-2 py-2 rounded-full animate-ping" />
                            }
                        </p>
                    </div>
                    <div className="flex items-center justify-center">
                        <div
                            tabIndex={0}
                            className={`flex items-center justify-center focus:bg-white hover:bg-white w-[40px] h-[40px] rounded-full transition duration-300 focus:text-green-500 hover:text-green-500 ${isNew ? '' : 'hidden'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsNew(false);
                            }}
                            onKeyDown={(e) => { if (e.key === 'Enter') { e.stopPropagation(); setIsNew(false); } }}
                        >
                            <FontAwesomeIcon icon={faTimes} className="text-2xl transition duration-300" />
                        </div>
                    </div>
                </div>
            </div>

            {isSectionOpen && (<style>{`body { overflow: hidden; }`}</style>)}

            <AnimatePresence>
                {isSectionOpen && (
                    <motion.section
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed top-0 right-0 bg-black/50 w-screen h-full z-[999999999999999999] flex flex-col items-center overflow-y-auto overflow-x-hidden pt-20 cursor-pointer"
                        onClick={() => setIsSectionOpen(false)}
                    >
                        <motion.div
                            initial={{ marginTop: '100px' }}
                            animate={{ marginTop: '0px' }}
                            exit={{ marginTop: '100px' }}
                            className="bg-gradient-to-b from-white to-gray-100 w-[calc(100vw-40px)] lg:w-[calc(100vw-321px)] p-8 md:p-12 rounded-3xl shadow-2xl flex flex-col mb-10 cursor-auto"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <header className="mb-4 border-b-2 border-gray-400 pb-4 flex justify-between items-start flex-col md:flex-row">
                                <h1 className="ml-3 text-2xl md:text-[45px] font-extrabold gap-2 flex items-center text-gray-900">
                                    <IconSVG className="mr-3 w-16 h-16 text-brand" />
                                    {t('releasenote:title')}
                                </h1>
                                <p className="mt-1 md:mt-4 text-gray-600 mr-3">
                                    <FontAwesomeIcon icon={faCodeBranch} className="mr-2" />
                                    {t('releasenote:version')} {type !== 'News' ? version : '?'}
                                </p>
                            </header>


                            <article className="p-6 border-b border-gray-300 mb-4">

                                <h1 className="text-3xl font-bold text-gray-800 flex items-center hyphens-auto word-break">
                                    <FontAwesomeIcon icon={type !== 'News' ? faFileAlt : faNewspaper} className="text-2xl mr-2" />
                                    {parseContent(title)}
                                </h1>


                                <h2 className="text-lg font-medium text-gray-600 mt-3 mb-4 flex flex-col sm:flex-row items-start justify-start">
                                    {type !== 'News' && (
                                        <>
                                            <span>
                                                <FontAwesomeIcon icon={faCodeBranch} className="mr-2" />
                                                {t('releasenote:version')}: {version}
                                            </span>
                                            <span className="hidden sm:block mx-2">-</span>
                                        </>
                                    )}
                                    <span>
                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                                        {t('releasenote:releaseDate')}: {date}
                                    </span>
                                </h2>


                                <p className="ml-2 text-gray-700 leading-relaxed break-words">
                                    {parseContent(content)}
                                </p>

                            </article>
                        </motion.div>
                    </motion.section>
                )}
            </AnimatePresence>
        </>
    );
};

export default ReleaseNotePreview;
