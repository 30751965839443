import { useTranslation } from "react-i18next";
import { DeviceFrameset } from 'react-device-frameset';
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faShareNodes, faSquareList } from "@fortawesome/pro-light-svg-icons";
import iconanimationred from "../../../Assets/iconanimationred.gif";
import { useAppSelector } from '../../../Redux/store';
import MyCard from "./MyCard";
import News from "./News";
import Socials from "./Socials";

interface MobileAppProps {
    activeItem: 'mycard' | 'news' | 'socials';
    children?: React.ReactNode;
    customIcon?: string;
    customColor?: boolean;
    customColorPrimary?: string;
    customColorSecondary?: string;
    fullOverlay?: boolean;
    showDefaultPage?: boolean;
    allowMovement?: boolean;
}

const MobileApp: React.FC<MobileAppProps> = ({ activeItem, children, customIcon, customColor, customColorPrimary, customColorSecondary, fullOverlay, showDefaultPage, allowMovement }) => {
    const { t } = useTranslation();
    const [fileBlob, setFileBlob] = useState<string>('');
    const theme = useAppSelector((s) => s.organisation.settings?.theme);
    const [isActive, setIsActive] = useState(activeItem);

    useEffect(() => {
        if (!theme) return;
        setFileBlob(theme.icon);
    }, [theme]);

    return (
        <>
            {customColor && (
                <style>{`
                    :root {
                        --color-accent: ${customColorPrimary};
                        --color-accent-light: ${customColorSecondary};
                    }
                `}</style>
            )}
            <DeviceFrameset device="iPhone X" color="black" style={{ width: "428px", height: "767px", overflow: "hidden", position: "relative" }}>
                <div tabIndex={-1} className={`${fullOverlay && 'blur-sm'} font-app w-full h-full overflow-x-hidden`}>
                    <div className="bg-white p-5">
                        <img src={iconanimationred} className="mt-6 ml-6 w-6 h-6" />
                    </div>

                    <div className='absolute left-[147px] top-[40px] w-[80px] h-[80px] flex justify-center items-center z-10'>
                        {(fileBlob || customIcon) && (
                            <img className='max-w-[80px] max-h-[80px]' src={customIcon || fileBlob} alt='Logo' />
                        )}
                    </div>

                    <div tabIndex={-1} className="bg-[#F6F8FA] w-full h-[80%] overflow-y-auto overflow-x-hidden no-scrollbar">
                        {showDefaultPage && !allowMovement && activeItem === 'mycard' && <MyCard />}
                        {showDefaultPage && !allowMovement && activeItem === 'news' && <News />}
                        {showDefaultPage && !allowMovement && activeItem === 'socials' && <Socials />}

                        {allowMovement && isActive === 'mycard' && (activeItem !== 'mycard' ? <MyCard /> : <>{children}</>)}
                        {allowMovement && isActive === 'news' && (activeItem !== 'news' ? <News /> : <>{children}</>)}
                        {allowMovement && isActive === 'socials' && (activeItem !== 'socials' ? <Socials /> : <>{children}</>)}
                        
                        {!fullOverlay && !allowMovement && <>{children}</>}
                    </div>

                    <div className="bg-white border-t-[3px] border-gray-100 mt-12 mx-[-10px] flex justify-center absolute bottom-0 w-[calc(100%+20px)]">
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => {allowMovement && setIsActive('mycard')}} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'mycard' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faQrcode} className="text-2xl" />
                                {t("cards:app:mycard")}
                            </div>
                        </div>
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => {allowMovement && setIsActive('news')}} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'news' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faSquareList} className="text-2xl" />
                                {t("cards:app:news")}
                            </div>
                        </div>
                        <div className={`text-[15px] w-1/3 whitespace-nowrap flex justify-center`}>
                            <div onClick={() => {allowMovement && setIsActive('socials')}} className={`py-3 w-[63%] flex flex-col items-center border-t-[2px] text-[12px] gap-2 ${allowMovement ? 'cursor-pointer' : 'cursor-not-allowed'} ${isActive === 'socials' ? 'border-accent text-accent' : 'hover:text-accent-light hover:border-accent-light hover:opacity-100 border-transparent opacity-50'}`}>
                                <FontAwesomeIcon icon={faShareNodes} className="text-2xl" />
                                {t("cards:app:socials")}
                            </div>
                        </div>
                    </div>
                </div>

                {fullOverlay && <>{children}</>}
            </DeviceFrameset>
        </>
    );
};

export default MobileApp;
