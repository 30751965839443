import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSuggestionQuery, usePutSuggestionMutation } from '../../../../../Redux/Api/Suggestion';
import BusyArea from '../../../../Shared/BusyArea';
import Button from '../../../../Shared/Form/Button';
import Form from '../../../../Shared/Form/Form';
import Input, { FailedRequirements } from '../../../../Shared/Form/Input';
import Title from '../../../../Shared/Title';
import { useTranslation } from 'react-i18next';
import StatusPopups from '../../../../Shared/Form/StatusPopups';
import Page404 from '../../../Page404';
import LoadingPage from '../../../LoadingPage';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

type SuggestionResponseItem = {
    title: string;
    // @ts-ignore
    text: {
        text_nl?: string;
        text_en?: string;
        text_de?: string;
        text_fr?: string;
        text_es?: string;
    };
    [key: string]: string | undefined;
};


const SuggestionEditPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { t, i18n } = useTranslation();

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    if (!id) return <Page404 />;

    const SuggestionRequest = { id };
    const { data, refetch, isFetching } = useGetSuggestionQuery(SuggestionRequest);
    const [putOrganisation] = usePutSuggestionMutation();

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    useEffect(() => {
        refetch();
    }, []);

    const submit = async (data: SuggestionResponseItem) => {
        if (busy) return;
        setSubmitted(true);
        if(FailedRequirements(data, 'title', 'text_nl', 'text_en', 'text_de', 'text_fr', 'text_es')) return setError(t('genericError') || "");
        setBusy(true);
        setError('');
        setSuccessMessage('');

        for (const language of languages) {
            const textKey = `text_${language}`;
            if (data[textKey] && data[textKey]!.length > 150) {
                setError(`${t('Suggestion:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }

        try {
            const outData = {
                title: data.title,
                text: {
                    text_nl: data.text_nl || '',
                    text_en: data.text_en || '',
                    text_de: data.text_de || '',
                    text_fr: data.text_fr || '',
                    text_es: data.text_es || '',
                },
            };

            await putOrganisation([id, outData]).unwrap();
            navigate(`/${i18n.language}/dashboard/admin/suggestion/list`);
        } catch (e) {
            setError(t('modal:error') as string);
        } finally {
            setBusy(false);
        }
    };

    if (isFetching || !data) return <LoadingPage />;

    const SuggestionData = data as unknown as SuggestionResponseItem;

    return (
        <>
            <Title text={t('patrickai:changeSuggestion')} textNotBold />
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <StatusPopups setText={setError} type='error' text={error} />
                    <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:url')} id='title' initial={SuggestionData.title} />
                    <h1 className='text-2xl font-bold'>{t('patrickai:suggestionText')}</h1>
                    <br />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(NL)'} id='text_nl' initial={SuggestionData.text.text_nl || ''} />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(EN)'} id='text_en' initial={SuggestionData.text.text_en || ''} />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(DE)'} id='text_de' initial={SuggestionData.text.text_de || ''} />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(FR)'} id='text_fr' initial={SuggestionData.text.text_fr || ''} />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(ES)'} id='text_es' initial={SuggestionData.text.text_es || ''} />
                    <Button iconright icon={faFloppyDisk} title={t('patrickai:save')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default SuggestionEditPage;
