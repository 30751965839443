import { faFileImport, faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useTranslation } from "react-i18next";
import Button from './Button';

export interface ColorPickerProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label: string,
    sublabel?: string,
    initial?: string,
    id?: string,
    color?: string,
    onChange?: (color: string) => void,
    onChangeReactive?: (color: string) => void,
}

const ColorPicker = (props: ColorPickerProps) => {
    const { t } = useTranslation();
    const { label, sublabel, onChange, color, onChangeReactive, initial, id, ...restProps } = props;

    const labelOrId = id || label;

    const [value, setValue] = useState(initial || '');
    const [isOpen, setIsOpen] = useState(false);
    const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [hoveredColor, setHoveredColor] = useState<string | null>(null);

    const itemRef = useRef<HTMLDivElement>(null);

    const change = (col: ColorResult) => {
        setValue(col.hex);
        if (onChangeReactive) onChangeReactive(col.hex);
    };

    useEffect(() => {
        if (color) setValue(color);
    }, [color]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isOpen) {
                setIsOpen(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen]);

    const handleCanvasHover = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        const canvas = e.currentTarget;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const pixel = ctx.getImageData(x, y, 1, 1).data;
            const hex = `#${pixel[0].toString(16).padStart(2, '0')}${pixel[1].toString(16).padStart(2, '0')}${pixel[2].toString(16).padStart(2, '0')}`;
            setHoveredColor(hex);
        }
    };

    const handleImageClick = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        const canvas = e.currentTarget;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const pixel = ctx.getImageData(x, y, 1, 1).data;
            const hex = `#${pixel[0].toString(16).padStart(2, '0')}${pixel[1].toString(16).padStart(2, '0')}${pixel[2].toString(16).padStart(2, '0')}`;
            setValue(hex);
            if (onChangeReactive) onChangeReactive(hex);
            if (onChange) onChange(hex);
            setIsImagePickerOpen(false);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target?.result as string);
                setIsImagePickerOpen(true);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div {...restProps} className='flex flex-col mb-6'>
            {<motion.div animate={(value.length > 0) ? { height: '0rem' } : { height: '1.2rem' }} className='text-sm overflow-hidden text-red-400 mb-2'>{(value.length <= 0 && t('required')) || '\u00a0'}</motion.div>}
            <label htmlFor={labelOrId}>{label}<span className='text-red-400'>*</span> {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}</label>
            <div className='flex flex-grow mt-1 flex-col relative'>
                <div tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && setIsOpen((s) => !s)} ref={itemRef} onClick={() => setIsOpen((s) => !s)} className='rounded-[0.4rem] flex items-center cursor-pointer border-[1px] border-black border-opacity-10 flex-grow p-1'>
                    <div style={{ background: value }} className="w-4 h-4 ml-1 mr-2 rounded-full"></div>
                    {value || '\u00a0'}
                </div>
                <AnimatePresence>
                    {isOpen &&
                        <>
                            <motion.div key="background-div" onClick={() => setIsOpen(false)} initial={{ opacity: 0 }} animate={{ opacity: 0.3 }} exit={{ opacity: 0 }} className='z-[1000] fixed left-0 top-0 w-screen h-screen bg-black/50' />

                            <motion.div initial={{ opacity: 0, y: 15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 15 }} className='absolute left-0 right-0 top-0 -mt-[15.6rem] p-2 rounded-[0.4rem] overflow-hidden shadow-xl z-[1001] flex flex-col sm:flex-row bg-white'>
                                <div className='flex sm:justify-start justify-center'>
                                    <div className='overflow-hidden rounded-[0.4rem] border-[1px] border-black border-opacity-10 min-w-[226px] max-w-[226px]'>
                                        <ChromePicker disableAlpha color={value} onChangeComplete={(col) => onChange && onChange(col.hex)} onChange={change} />
                                    </div>
                                </div>

                                <div className='pl-2 flex flex-col justify-between gap-2 mt-2 w-full sm:mt-0'>
                                    <div className='flex flex-col gap-2'>
                                        <Button tabIndex={-1} nosubmit title={t('colorFromImage')} icon={faFileImport} action={() => document.getElementById(`${labelOrId}-file-input`)?.click()} />
                                        {imageSrc && <Button nosubmit title={t('tryAgain')} icon={faRepeat} action={() => setIsImagePickerOpen(true)} />}
                                    </div>
                                    <Button onBlur={() => setIsOpen(false)} nosubmit secondary title={t('close')} action={() => setIsOpen(false)} />
                                </div>
                            </motion.div>
                        </>
                    }
                </AnimatePresence>

                <AnimatePresence>
                    {isImagePickerOpen && imageSrc && (
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => setIsImagePickerOpen(false)} className="fixed inset-0 z-[1002] flex items-center justify-center bg-black/50">
                            <div onClick={(e) => e.stopPropagation()} className="relative bg-white p-4 rounded-[0.4rem] shadow-xl overflow-auto">
                                <canvas
                                    ref={(canvas) => {
                                        if (canvas) {
                                            const ctx = canvas.getContext("2d");
                                            const img = new Image();
                                            img.src = imageSrc;
                                            img.onload = () => {
                                                const scale = Math.min(0.9 * window.innerWidth / img.width, 0.9 * window.innerHeight / img.height, 1);
                                                const width = img.width * scale;
                                                const height = img.height * scale;
                                                canvas.width = width;
                                                canvas.height = height;
                                                ctx && ctx.drawImage(img, 0, 0, width, height);
                                            };
                                        }
                                    }}
                                    onMouseMove={handleCanvasHover}
                                    onClick={handleImageClick}
                                    className="cursor-crosshair max-w-[90vw] max-h-[90vh] border-dashed border-[1px] border-black border-opacity-40 rounded-[0.2rem]"
                                />
                                <div className="flex items-center mt-2 gap-2 justify-between">
                                    <button type="button" className="text-red-500" onClick={() => setIsImagePickerOpen(false)}>
                                        {t('close')}
                                    </button>
                                    {hoveredColor && (
                                        <div className="flex items-center gap-1">
                                            <div
                                                className="w-6 h-6 rounded-full border border-black"
                                                style={{ backgroundColor: hoveredColor }}
                                            ></div>
                                            <span className="text-sm">{hoveredColor}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <input id={`${labelOrId}-file-input`} type="file" accept="image/*" className="hidden" onChange={handleFileUpload} />
            <input value={value} type='hidden' name={labelOrId} id={labelOrId} />
            <input name={'@VALID@' + labelOrId} type="hidden" value={(value.length > 0) + ''} />
        </div>
    );
};

export default ColorPicker;
