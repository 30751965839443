import React, { useState, useEffect } from 'react';
import { usePostReleaseNoteMutation } from '../../../../../Redux/Api/ReleaseNote';
import BusyArea from '../../../../Shared/BusyArea';
import Button from '../../../../Shared/Form/Button';
import Form from '../../../../Shared/Form/Form';
import Input, { FailedRequirements } from '../../../../Shared/Form/Input';
import Title from '../../../../Shared/Title';
import { Trans, useTranslation } from 'react-i18next';
import StatusPopups from '../../../../Shared/Form/StatusPopups';
import { all, max, min } from "../../../../../Utils/InValidation";
import info from "../../../../../Assets/info.svg";
import DropdownInput from "../../../../Shared/Form/DropdownInput";
import ReleaseNotePreview from "./ReleaseNotePreview";
import FilePicker from '../../../../Shared/Form/FilePicker';
import { faAdd, faFileAlt, faNewspaper } from '@fortawesome/pro-solid-svg-icons';
import Card from '../../../../Shared/Card/Card';
import CardGrid from '../../../../Shared/Card/CardGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReleaseNodeCreatePage = () => {
    const [postReleaseNote] = usePostReleaseNoteMutation();

    const { t, i18n } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [initialVersion, setInitialVersion] = useState('');

    const [title, setTitle] = useState('');
    const [version, setVersion] = useState('');

    const [title_nl, setTitle_nl] = useState('');
    const [content_nl, setContent_nl] = useState('');

    const [title_en, setTitle_en] = useState('');
    const [content_en, setContent_en] = useState('');

    const [title_de, setTitle_de] = useState('');
    const [content_de, setContent_de] = useState('');

    const [title_fr, setTitle_fr] = useState('');
    const [content_fr, setContent_fr] = useState('');

    const [title_es, setTitle_es] = useState('');
    const [content_es, setContent_es] = useState('');

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    const [type, setType] = useState('Release note');

    useEffect(() => {
        const hash = window.location.hash;
        const versionMatch = hash.match(/version=([\d\.]+)/);
        if (versionMatch) {
            setInitialVersion(versionMatch[1]);
        }
    }, []);

    const submit = async (data: {
        title: string,
        version: string,
        text_nl: string,
        text_en: string,
        text_de: string,
        text_fr: string,
        text_es: string,
        content_nl: string,
        content_en: string,
        content_de: string,
        content_fr: string,
        content_es: string
    }) => {
        setSubmitted(true);
        if (FailedRequirements(data, 'title', 'version', 'text_nl', 'text_en', 'text_de', 'text_fr', 'text_es', 'content_nl', 'content_en', 'content_de', 'content_fr', 'content_es')) return setError(t("genericError") || "")
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        for (const language of languages) {
            if ((data as any)[`text_${language}`].length > 150) {
                setError(`${t('releasenote:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }

        try {
            const outData = {
                title: data.title,
                type: type ? type : 'Release note',
                text: {
                    text_nl: data.text_nl,
                    text_en: data.text_en,
                    text_de: data.text_de,
                    text_fr: data.text_fr,
                    text_es: data.text_es,
                },
                content: {
                    content_nl: data.content_nl,
                    content_en: data.content_en,
                    content_de: data.content_de,
                    content_fr: data.content_fr,
                    content_es: data.content_es,
                },
                version: data.version,
            };

            await postReleaseNote([outData]).unwrap();

            setSuccessMessage(t('releasenote:successAdd') as string);
            setBusy(false);
            setTimeout(() => {
                window.location.replace(`/${i18n.language}/dashboard`);
            }, 1000);
        } catch (e) {
            setError(t('modal:error') as string);
            setBusy(false);
        }
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const content = await file.text();
            const lines = content.split('\n');

            const parsedData: { [key: string]: string } = {};
            let currentKey = '';
            let buffer = '';

            lines.forEach((line) => {
                const keyMatch = line.match(/^(.*?): \[$/);
                const endMatch = line.match(/^(.*?)]$/);

                if (keyMatch) {
                    if (currentKey && buffer) {
                        parsedData[currentKey] = buffer.trim();
                    }

                    currentKey = keyMatch[1].trim();
                    buffer = '';
                } else if (endMatch && currentKey) {
                    buffer += line.replace(/^(.*?)]$/, '').trim();
                    parsedData[currentKey] = buffer.trim();
                    currentKey = '';
                    buffer = '';
                } else if (currentKey) {
                    buffer += line + '\n';
                }
            });

            if (currentKey && buffer) {
                parsedData[currentKey] = buffer.trim();
            }

            setTitle(parsedData['Title'] || '');
            setVersion(parsedData['Version'] || '');
            setType(parsedData['Type'] || '');
            setTitle_nl(parsedData['Title (NL)'] || '');
            setContent_nl(parsedData['Content (NL)'] || '');
            setTitle_en(parsedData['Title (EN)'] || '');
            setContent_en(parsedData['Content (EN)'] || '');
            setTitle_de(parsedData['Title (DE)'] || '');
            setContent_de(parsedData['Content (DE)'] || '');
            setTitle_fr(parsedData['Title (FR)'] || '');
            setContent_fr(parsedData['Content (FR)'] || '');
            setTitle_es(parsedData['Title (ES)'] || '');
            setContent_es(parsedData['Content (ES)'] || '');

        } catch (error) {
            console.error("Failed to import file:", error);
        }
    };



    return (
        <>
            <Title text={t('releasenote:createReleasenote')} textNotBold />
            <BusyArea busy={busy}>

                <FilePicker label={t('releasenote:importFile')} accept={['text/plain']} onChange={(file) => handleFileUpload({ target: { files: [file] } } as unknown as React.ChangeEvent<HTMLInputElement>)} />

                <Form submit={submit}>
                    <StatusPopups setText={setError} type="error" text={error} />
                    <StatusPopups setText={setSuccessMessage} type="success" text={successMessage} />
                    <Input value={title} onChange={(e) => setTitle((e.target as HTMLInputElement).value)} submitted={submitted ? true : false} required label={t('releasenote:name')} id='title' />

                    <h1 className='-mb-4'>{t('cards:list:selectType')}<span className='text-red-400'>*</span></h1>
                    <CardGrid cols={2}>
                        <Card
                            icon={faFileAlt}
                            hideOverflow
                            className={`transition duration-300 ease-in-out ${type === 'Release note'
                                ? 'bg-blue-100'
                                : 'bg-light-200 focus:bg-gray-200 hover:bg-gray-200'
                                }`}
                            tabIndex={0}
                            onKeyDown={(e) => e.key === 'Enter' && setType('Release note')}
                            onClick={() => setType('Release note')}
                            outline
                            rounded>
                            <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                                <div>
                                    <input
                                        tabIndex={-1}
                                        className='mr-2 accent-blue-500'
                                        type="radio"
                                        name="role"
                                        value="Release note"
                                        checked={type === 'Release note'}
                                    />
                                    {t('releasenote:typeReleaseNote')}
                                </div>
                                <FontAwesomeIcon icon={faFileAlt} className={`ml-2 transition ${type === 'Release note' ? 'opacity-75' : 'opacity-25'}`} />
                            </label>
                            <p className="ml-1 text-sm text-gray-600 z-[2]">{t('releasenote:typeReleaseNoteDescription')}</p>
                        </Card>
                        <Card
                            icon={faNewspaper}
                            hideOverflow
                            className={`transition duration-300 ease-in-out ${type === 'News'
                                ? 'bg-blue-100'
                                : 'bg-light-200 focus:bg-gray-200 hover:bg-gray-200'
                                }`}
                            tabIndex={0}
                            onKeyDown={(e) => e.key === 'Enter' && setType('News')}
                            onClick={() => setType('News')}
                            outline
                            rounded>
                            <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                                <div>
                                    <input
                                        tabIndex={-1}
                                        className='mr-2 accent-blue-500'
                                        type="radio"
                                        name="role"
                                        value="News"
                                        checked={type === 'News'}
                                    />
                                    {t('releasenote:typeNews')}
                                </div>
                                <FontAwesomeIcon icon={faNewspaper} className={`ml-2 transition ${type === 'News' ? 'opacity-75' : 'opacity-25'}`} />
                            </label>
                            <p className="ml-1 text-sm text-gray-600 z-[2]">{t('releasenote:typeNewsDescription')} {t('releasenote:ifNews')}</p>
                        </Card>
                    </CardGrid>

                    <Input value={version} placeholder={t('releasenote:previousVersion') + ': ' + (initialVersion)} submitted={submitted ? true : false} required label={t('releasenote:version')} id='version' onChange={(e) => setVersion((e.target as HTMLInputElement).value)} />

                    <div className='p-3 mb-3 bg-white rounded-[0.4rem]'>
                        <div className='flex mb-2 items-center'>
                            <img src={info} className='mr-2' />
                            <h1 className='text-2xl font-bold'>{t('releasenote:howTobuildAReleaseNote')}</h1>
                        </div>
                        <p className='mb-4'><Trans i18nKey="releasenote:howTobuildAReleaseNoteDescription" components={{ br: <br /> }} /></p>
                    </div>

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_nl ? title_nl : '?'} content={content_nl ? content_nl : '?'} />

                    <Input submitted={submitted ? true : false} value={title_nl} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(NL)'} id='text_nl' onChange={(e) => setTitle_nl((e.target as HTMLInputElement).value)} />
                    <Input submitted={submitted ? true : false} value={content_nl} larger required label={t('releasenote:content') + '(NL)'} id='content_nl' onChange={(e) => setContent_nl((e.target as HTMLInputElement).value)} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_en ? title_en : '?'} content={content_en ? content_en : '?'} />

                    <Input submitted={submitted ? true : false} value={title_en} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(EN)'} id='text_en' onChange={(e) => setTitle_en((e.target as HTMLInputElement).value)} />
                    <Input submitted={submitted ? true : false} value={content_en} larger required label={t('releasenote:content') + '(EN)'} id='content_en' onChange={(e) => setContent_en((e.target as HTMLInputElement).value)} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_de ? title_de : '?'} content={content_de ? content_de : '?'} />

                    <Input submitted={submitted ? true : false} value={title_de} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(DE)'} id='text_de' onChange={(e) => setTitle_de((e.target as HTMLInputElement).value)} />
                    <Input submitted={submitted ? true : false} value={content_de} larger required label={t('releasenote:text') + '(DE)'} id='content_de' onChange={(e) => setContent_de((e.target as HTMLInputElement).value)} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_fr ? title_fr : '?'} content={content_fr ? content_fr : '?'} />

                    <Input submitted={submitted ? true : false} value={title_fr} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(FR)'} id='text_fr' onChange={(e) => setTitle_fr((e.target as HTMLInputElement).value)} />
                    <Input submitted={submitted ? true : false} value={content_fr} larger required label={t('releasenote:content') + '(FR)'} id='content_fr' onChange={(e) => setContent_fr((e.target as HTMLInputElement).value)} />

                    <ReleaseNotePreview type={type} version={version ? version : '?'} title={title_es ? title_es : '?'} content={content_es ? content_es : '?'} />

                    <Input submitted={submitted ? true : false} value={title_es} invalidator={all(min(1), max(150))} required label={t('releasenote:text') + '(ES)'} id='text_es' onChange={(e) => setTitle_es((e.target as HTMLInputElement).value)} />
                    <Input submitted={submitted ? true : false} value={content_es} larger required label={t('releasenote:content') + '(ES)'} id='content_es' onChange={(e) => setContent_es((e.target as HTMLInputElement).value)} />

                    <Button iconright icon={faAdd} title={t('releasenote:add')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default ReleaseNodeCreatePage;
