import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React, { useState } from 'react'

export interface SwitchProps {
    label: string,
    sublabel?: string,
    id?: string,
    onClick?: (value:boolean) => void
}

const Switch = ({ id, label, sublabel, onClick }: SwitchProps) => {

    const labelOrId = id || label

    const [ state, setState ] = useState(false)

    return (
        <div className='flex gap-4 my-4 cursor-pointer' onClick={ () => {
            const newState = !state;
            setState(newState);
            onClick && onClick(newState);
        }}>
            <label htmlFor={ labelOrId }>{ label } { sublabel && <span className='opacity-70 text-sm'>({ sublabel })</span> }</label>
            <div tabIndex={0} onKeyDown={ (e) => e.key === 'Enter' && setState(!state) } role='switch' className='ml-1 h-6 w-6 bg-light-300 flex items-center justify-center rounded-md'>
                <motion.div initial={{ scale: 0}} animate={{ scale: state ? 1 : 0 }}>
                    <FontAwesomeIcon className='scale-75' icon={ faCheck } />
                </motion.div>
            </div>
            <input value={ state + '' } type='hidden' name={ labelOrId } id={ labelOrId } />
        </div>
    )
}

export default Switch