import moment from 'moment'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
    useAddCardPhotoMutation,
    useGetBlocksQuery,
    useGetCardQuery,
    usePostActivationLinkEmailMutation,
    usePostBlockMutation,
    usePostUnblockMutation,
    usePutCardMutation, useRemoveCardPhotoMutation,
} from '../../../../Redux/Api/Cards';
import { useGetTypeQuery, usePostTypeMutation, useDeleteTypeMutation } from '../../../../Redux/Api/Types';
import { all, isInt, isPositive, max, min } from '../../../../Utils/InValidation'
import { CardEditItem, CardResponseItem } from '../../../../Redux/Api/Request/Cards'
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import CalendarInput from '../../../Shared/Form/CalendarInput'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Pill from '../../../Shared/Form/Pill'
import DropdownInput from '../../../Shared/Form/DropdownInput'
import { Hydra } from '../../../../Utils/Hydra'
import Constants from '../../../../Constants'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import Page404 from '../../Page404'
import Switch from "../../../Shared/Form/Switch";
import { useLazyGetOrganisationSettingsQuery, useLazyGetTypeDataQuery } from "../../../../Redux/Api/Organisations";
import { useTranslation } from "react-i18next";
import ImageInput from "../../../Shared/Form/ImageInput";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import ConfirmationDialog from "../../../Shared/Form/ConfirmationDialog";


import IconSVG from '../../../Assets/icon.svg?component'
import nlflag from '../../../../Assets/nl.svg'
import enflag from '../../../../Assets/en.svg'
import deflag from '../../../../Assets/de.svg'
import esflag from '../../../../Assets/es.svg'
import frflag from '../../../../Assets/fr.svg'
import { InputLabel, MenuItem, Select, responsiveFontSizes } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Background } from 'victory';
import { Language } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft, faEnvelope, faEnvelopeOpen, faEnvelopeOpenText, faFloppyDisk, faLock, faLockOpen, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from "framer-motion";


const CardEditPage = () => {

    const modalContext = useContext(ModalContext)
    const { id } = useParams()
    const { t, i18n } = useTranslation()

    if (!id) return <Page404 />

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()

    const [putCard] = usePutCardMutation()
    const [postBlock] = usePostBlockMutation()
    const [postUnblock] = usePostUnblockMutation()
    const [sendActivationLink] = usePostActivationLinkEmailMutation()
    const [postType] = usePostTypeMutation()
    const [deleteType] = useDeleteTypeMutation()
    const [uploadFile] = useUploadMutation()
    const [addPhoto] = useAddCardPhotoMutation()
    const [removePhoto] = useRemoveCardPhotoMutation()

    const { data, refetch: refetchCard, isError } = useGetCardQuery({
        id: id!,
    })

    const { data: blocks, refetch: refetchBlocks } = useGetBlocksQuery(id!)
    const [getOrganisationTypes, { data: orgTypes }] = useLazyGetTypeDataQuery();

    const [isBlocked, setIsBlocked] = useState(false)
    const [errorBlock, setErrorBlock] = useState('')
    const [errorMail, setErrorMail] = useState('')
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [submittedBlock, setSubmittedBlock] = useState(false);
    const [busy, setBusy] = useState(false)
    const [types, setTypes] = useState<Array<string>>([])
    const [newType, setNewType] = useState<string>('')

    const [check, setCheck] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [successAddMessage, setSuccessAddMessage] = useState('')
    const [email, setEmail] = useState('')
    const [activeFrom, setActiveFrom] = useState('')
    const [infinite, setInfinite] = useState(true)
    const [photo, setPhoto] = useState<Blob>()
    const [deletePhoto, setDeletePhoto] = useState(false)
    const [lastAction, setLastAction] = useState('')
    const location = useLocation();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const navigate = useNavigate();

    const url = window.location.href;
    const isNew = url.includes("#new-card");

    useEffect(() => {
        if (sessionStorage.getItem('successMessage')) {
            setSuccessAddMessage(sessionStorage.getItem('successMessage')!);
            sessionStorage.removeItem('successMessage');
        }
    }, [org_id])

    useEffect(() => {
        if (!blocks) return

        setIsBlocked(blocks.length > 0)
    }, [blocks])

    useEffect(() => {
        if (org_id) {
            getOrganisationTypes({
                id: org_id
            })
            getOrganisationsSettings(org_id)
        }
    }, [org_id])

    const toMYSQLDate = (datestring: string | number) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const requestDeleteType = async (id: string) => {
        if (id) {
            setBusy(true);
            await deleteType(id);

            refetchCard();
            getOrganisationTypes({ id: org_id });
            setBusy(false);
        }
    };


    const makeUnique = (arr: Array<string>) => {
        const unique = [...new Set(arr)]
        return unique
    }

    useEffect(() => {
        if (!data || !orgTypes) return

        let preTypes = []

        for (let type of data.types) {
            let item = orgTypes.find(x => x.id == type.slice('/api/types/'.length))
            if (item) {
                preTypes.push(item.name)
            }
        }

        setTypes(preTypes)
        setEmail(data.email || '')
        setActiveFrom(data.activeFrom)
    }, [data, orgTypes])

    const makeNumberValid = (min: number, max: number, input: number) => {
        if (input >= min && input <= max) return input
        if (input <= min) return min
        if (input >= max) return max
    }

    const submit = async (data: {
        initials: string,
        lastname: string,
        image: File,
        email: string,
        numberOfPeople: number,
        dateOfBirth: string,
        location: string,
        telephoneNumber: string,
        department: string,
        activeFrom: string,
        activeUntil: string,
        membershipId: string,
        type: string,
        countryCode: string,
        discount: string,
        team: string
    }) => {
        if (FailedRequirements(data, 'initials', 'lastname', 'email', 'activeFrom', 'membershipId')) return setError(t('modal:error') as string)
        if (busy) return

        setSubmitted(true)
        setBusy(true)
        setError('')
        setErrorBlock('')
        setSuccessMessage('')

        try {
            if (photo && lastAction === 'upload') {
                const uploadablePhoto = new File([photo], 'uploaded_photo');

                const upload_id = await uploadFile({
                    file: uploadablePhoto
                }).unwrap()

                await addPhoto({
                    card_id: id,
                    upload_id
                })
            }

            if (deletePhoto && lastAction === 'delete') {
                removePhoto(id)
            }

            const outData: Omit<CardEditItem, 'id' | 'organisationId'> = {
                initials: data.initials,
                lastname: data.lastname,
                membershipId: data.membershipId,
                email: data.email,
                activeFrom: toMYSQLDate(data.activeFrom),
                activeUntil: toMYSQLDate(data.activeUntil === '' ? '9999-01-01' : data.activeUntil),
                dateOfBirth: data.dateOfBirth,
                location: data.location,
                telephoneNumber: data.telephoneNumber,
                department: data.department,
                countryCode: data.countryCode,
                discount: data.discount,
                team: data.team,
                numberOfPeople: settings?.familyCards ? parseInt(String(makeNumberValid(1, settings.maxNumberOfPeople, data.numberOfPeople))) : 1, // typescript moment 🗿 -Michael Schuiling -Tim Dieters -Michael Schuiling - Tim Dieters
                types: []

            }

            let outTypes = []

            for (let type of types) {
                let item = orgTypes?.find(x => x.name == type)

                if (item) {
                    outData.types.push('/api/types/' + item.id)
                } else {
                    const id = await postType({
                        name: type.trim(),
                        organisation: org_id,
                        id: ''
                    }).unwrap()

                    outData.types.push('/api/types/' + id)
                }
            }

            await putCard({
                id,
                ...outData
            }).unwrap()

            refetchCard();
            getOrganisationTypes({ id: org_id });

            setSuccessMessage(t('cards:edit:success') as string)
            sessionStorage.setItem('cardChanged', 'true');
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const submitBlock = async (data: {
        reason: string,
        from: string,
        until: string,
        infinite: boolean
    }) => {
        if (busy) return

        setSubmittedBlock(true)
        setBusy(true)
        setError('')
        setErrorBlock('')
        setSuccessMessage('')

        try {
            let d = {
                from: toMYSQLDate(data.from),
                until: toMYSQLDate(data.until),
                reason: data.reason,
                infinite: infinite.toString()
            }

            if (infinite) {
                d = {
                    from: toMYSQLDate(new Date().toString()),
                    until: toMYSQLDate('9999-1-1'),
                    reason: data.reason,
                    infinite: infinite.toString()
                }
            }

            await postBlock([id!, d]).unwrap()
            refetchBlocks()

            sessionStorage.setItem('cardChanged', 'true');
            setSuccessMessage(t('cards:edit:successBlock') as string)
        } catch (e) {
            setErrorBlock(t('modal:error') as string)
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const submitUnblock = async () => {
        if (busy) return

        setBusy(true)
        setError('')
        setErrorBlock('')
        setSuccessMessage('')

        try {
            await postUnblock(id!).unwrap()
            refetchBlocks()

            sessionStorage.setItem('cardChanged', 'true');
            setSuccessMessage(t('cards:edit:successUnblock') as string)
        } catch (e) {
            let err = e as Hydra.Error
            setErrorBlock(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const submitEmail = async () => {
        if (!email) {
            return setErrorMail(t('cards:edit:noEmail') as string);
        }
        if (busy) return;

        setBusy(true);

        try {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const activeDate = new Date(activeFrom);
            activeDate.setHours(0, 0, 0, 0);

            if (activeDate > today) {
                setBusy(false);
                const formattedDate = activeDate.toLocaleDateString('nl-NL', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });

                setDialogTitle(t('cards:list:warningTitle') || '');
                setDialogMessage(t('cards:list:activationEmailWarning', { activeFrom: formattedDate }) || '');
                setDialogOpen(true);
                return;
            }

            await sendActivationEmail();
        } catch (e) {
            let err = e as Hydra.Error;
            setErrorMail(err['hydra:description']);
        } finally {
            setBusy(false);
        }
    };

    const handleDialogClose = async (confirm: boolean) => {
        setDialogOpen(false);

        if (confirm) {
            setBusy(true);
            try {
                await sendActivationEmail();
            } catch (e) {
                let err = e as Hydra.Error;
                setErrorMail(err['hydra:description']);
            } finally {
                setBusy(false);
            }
        }
    };

    const sendActivationEmail = async () => {
        setError('');
        setErrorBlock('');
        setSuccessMessage('');

        await sendActivationLink(id!);
        setSuccessMessage(t('cards:edit:emailSend') + email);
    };



    const [copiedMessages, setCopiedMessages] = useState<Array<{ x: number; y: number }>>([]);

    const copyLink = async (text: string, x: number, y: number) => {
        if ('clipboard' in navigator) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        } else {
            document.execCommand('copy', true, text);
        }
        setCopiedMessages((prev) => [...prev, { x, y }]);
    };

    const handleMouseClick = (e: React.MouseEvent<HTMLSpanElement>, text: string) => {
        copyLink(text, e.clientX, e.clientY);
    };

    const copyInviteLink = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (!data) return;
        handleMouseClick(e, Constants.INVITE_LINK_PREFIX + data.token);
    };

    const createNewType = () => {
        if (!newType) return
        const arrayOfTypes = types
        arrayOfTypes.push(newType)

        setTypes(makeUnique(arrayOfTypes))
        setNewType('')
    }

    const removeType = (type: string) => {
        setTypes(makeUnique(types.filter(t => t != type)))
    }

    const handleInfinite = () => {
        setInfinite(!infinite)
    }

    const handleCrop = (blob: Blob | null) => {
        if (blob) {
            setPhoto(blob)
            setLastAction('upload')
        }
    }

    const handleRemovePhoto = () => {
        setDeletePhoto(true)
        setLastAction('delete')
    }


    const [language, setLanguage] = useState('NL');

    function formatDate(until: string | null | undefined): string {
        if (!until) return '';
        const date = new Date(until);
        if (isNaN(date.getTime())) return '';
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };

        let lang = i18n.language;
        if (lang === 'en') {
            lang = 'en-GB';
        }

        return date.toLocaleDateString(lang, options);
    }


    if (isError) return <Page404 />
    if (!data) return <LoadingPage />


    return (
        <>
            <Title text={t('cards:edit:title')} textNotBold />
            <StatusPopups setText={setError} type='error' text={error} />
            <StatusPopups setText={setErrorBlock} type='error' text={errorBlock} />
            <StatusPopups setText={setErrorMail} type='error' text={errorMail} />
            <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
            <StatusPopups setText={setSuccessAddMessage} type='success' text={successAddMessage} />
            <div className='flex flex-col md:flex-row items-start gap-2 mb-4'>
                <Button icon={faArrowLeft} title={isNew ? t('cards:edit:goToList') : t('cards:edit:backToList')} action={() => navigate('/' + i18n.language + '/dashboard/cards/list')} />
                {isNew && <Button icon={faPlus} title={t('cards:edit:addNew')} action={() => navigate('/' + i18n.language + '/dashboard/cards/create')} />}
                <Button icon={data.emailactivation ? faEnvelopeOpen : faEnvelope} action={submitEmail} title={data.emailactivation ? t('cards:edit:reActivationMail') : t('cards:edit:activationMail')} />
            </div>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                message={dialogMessage}
                onClose={(confirm) => handleDialogClose(confirm)}
            />
            <Card free rounded outline>
                <div className='font-bold'>Download links</div>
                <div className='flex flex-wrap gap-x-1'>
                    <span>Google Play Store (Android):</span>
                    <span className='text-accent transition-colors cursor-pointer break-all' onClick={(e) => handleMouseClick(e, Constants.DOWNLOAD_URL_ANDROID)}>{Constants.DOWNLOAD_URL_ANDROID}</span>
                    <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyLink(Constants.DOWNLOAD_URL_ANDROID, 0, 0)} onClick={(e) => handleMouseClick(e, Constants.DOWNLOAD_URL_ANDROID)}>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='flex flex-wrap gap-x-1 mb-2'>
                    <span>App Store (Apple):</span>
                    <span className='text-accent transition-colors cursor-pointer break-all' onClick={(e) => handleMouseClick(e, Constants.DOWNLOAD_URL_IOS)}>{Constants.DOWNLOAD_URL_IOS}</span>
                    <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyLink(Constants.DOWNLOAD_URL_IOS, 0, 0)} onClick={(e) => handleMouseClick(e, Constants.DOWNLOAD_URL_IOS)}>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='font-bold'>{t('cards:edit:activationCode')}</div>
                <div className='flex flex-wrap gap-x-1'>
                    <span>{t('cards:edit:link')}</span>
                    <span className='text-accent transition-colors cursor-pointer break-all' onClick={copyInviteLink}>{Constants.INVITE_LINK_PREFIX + data.token}</span>
                    <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyInviteLink} onClick={copyInviteLink}>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='flex flex-wrap gap-x-1'>
                    <span>{t('cards:edit:code')}</span>
                    <span className='text-accent transition-colors cursor-pointer break-all' onClick={(e) => handleMouseClick(e, data.token || '')}>{data.token}</span>
                    <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyLink(data.token || '', 0, 0)} onClick={(e) => handleMouseClick(e, data.token || '')}>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='flex flex-wrap gap-x-1'>
                    <span>{t('cards:edit:use')}</span>
                    <span className='text-accent transition-colors cursor-pointer break-all' onClick={(e) => handleMouseClick(e, Constants.INVITE_CODE_LINK)}>{Constants.INVITE_CODE_LINK}</span>
                    <span className='opacity-40 cursor-pointer' tabIndex={0} onKeyDown={(e) => copyLink(Constants.INVITE_CODE_LINK, 0, 0)} onClick={(e) => handleMouseClick(e, Constants.INVITE_CODE_LINK)}>{t('cards:edit:clickToCopy')}</span>
                </div>
                {copiedMessages.map((pos, index) => (
                    <div key={index} className='fixed top-0 left-0 w-full h-full z-50 pointer-events-none'>
                        <div className='ml-[320px] lg:ml-0' style={{ marginLeft: pos.x, marginTop: pos.y, color: 'blue', padding: '5px 10px', borderRadius: '5px', whiteSpace: 'nowrap', pointerEvents: 'none', animation: 'fadeMoveUp 1.5s forwards', fontWeight: 'bold', zIndex: '110' }} >
                            {t('cards:edit:copied') as string}
                        </div>
                    </div>
                ))}
                <style>{` @keyframes fadeMoveUp { 0% { opacity: 1; transform: translateY(0); } 100% { opacity: 0; transform: translateY(-50px); } } `}</style>
            </Card>
            <BusyArea busy={busy}>
                <Form className='!mb-6 mt-6' submit={submit}>
                    <div className='block xl:flex'>
                        <div className='flex-grow'>
                            <Input
                                submitted={submitted ? true : false}
                                required
                                initial={data.initials}
                                invalidator={all(max(255))}
                                label={t('cards:list:initials')}
                                id='initials' />
                            <Input
                                submitted={submitted ? true : false}
                                required
                                initial={data.lastname}
                                invalidator={all(min(2), max(255))}
                                label={t('cards:list:lastname')}
                                id='lastname' />
                            <Input
                                submitted={submitted ? true : false}
                                initial={data.email!}
                                valueChange={setEmail}
                                required
                                invalidator={all(max(255))}
                                label={t('cards:list:email')}
                                type='email'
                                id='email' />
                        </div>
                        {settings?.photo ?
                            <div className='w-auto xl:w-[450px] mb-6 xl:mb-0 xl:ml-6'>
                                <ImageInput withSave onCrop={handleCrop} onDelete={handleRemovePhoto} image={data.photo} />
                            </div> : null
                        }

                    </div>
                    {settings?.familyCards ?
                        <Input
                            submitted={submitted ? true : false}
                            required
                            type='number'
                            //@ts-ignore
                            initial={data.numberOfPeople}
                            label={t('cards:edit:numberOfPeople')}
                            sublabel={'Max ' + settings.maxNumberOfPeople}
                            id='numberOfPeople' /> : null
                    }
                    <CalendarInput
                        submitted={submitted ? true : false}
                        required
                        className='mb-6'
                        initial={data.activeFrom}
                        label={t('cards:list:activeFrom')}
                        id='activeFrom' />
                    <CalendarInput
                        className='mb-6'
                        sublabel={t('cards:list:optional') as string}
                        initial={data.activeUntil != '9999-01-01T00:00:00+01:00' ? data.activeUntil : ''}
                        label={t('cards:list:activeUntil')}
                        id='activeUntil' />
                    {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                    {/*    <DateField*/}
                    {/*        label="Controlled field"*/}
                    {/*        value={data.activeUntil}*/}
                    {/*        // onChange={(newValue) => setValue(newValue)}*/}
                    {/*    />*/}
                    {/*</LocalizationProvider>*/}
                    <Input
                        submitted={submitted ? true : false}
                        required
                        initial={data.membershipId}
                        invalidator={all(min(2), max(255))}
                        label={t('cards:list:number')}
                        id='membershipId' />
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.dateOfBirth}
                        label={t('cards:list:dateOfBirth')}
                        id='dateOfBirth' />
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.location}
                        label={t('cards:list:location')}
                        id='location' />
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.telephoneNumber}
                        label={t('cards:list:phone')}
                        id='telephoneNumber' />
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.department}
                        label={t('cards:list:department')}
                        id='department' />

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.discount}
                        label={t('cards:list:discount')}
                        id='discount' />

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.team}
                        label={t('cards:list:team')}
                        id='team' />

                    <div className='flex flex-grow flex-col' style={{ paddingBottom: 35 }}>
                        <label htmlFor="countryCode">{t('cards:list:countryCode')}</label>

                        <Select
                            required
                            defaultValue={data.countryCode} onChange={(event) => setLanguage(event.target.value)}
                            id="countryCode"
                            name="countryCode"
                        >
                            <MenuItem value="NL"><img src={nlflag} alt="Dutch" id="NL" style={{ width: 30, height: 30, display: 'inline-block' }} className="mr-3 " />{t('organisation:theme:NL')}</MenuItem>
                            <MenuItem value="EN"><img src={enflag} alt="English" id="EN" style={{ width: 30, height: 30, display: 'inline-block' }} className="w-14 h-14 mr-3" />{t('organisation:theme:ENG')}</MenuItem>
                            <MenuItem value="DE"><img src={deflag} alt="German" id="DE" style={{ width: 30, height: 30, display: 'inline-block' }} className="w-14 h-14 mr-3" />{t('organisation:theme:DE')}</MenuItem>
                            <MenuItem value="FR"><img src={frflag} alt="France" id="FR" style={{ width: 30, height: 30, display: 'inline-block' }} className="w-14 h-14 mr-3" />{t('organisation:theme:FR')}</MenuItem>
                            <MenuItem value="ES"><img src={esflag} alt="Spain" id="ES" style={{ height: 20, width: 30, paddingTop: 1, paddingBottom: 1, display: 'inline-block' }} className="w-14 h-14 mr-3" />{t('organisation:theme:ES')} </MenuItem>

                        </Select>
                    </div>

                    <Card rounded title={t('cards:list:types')} free outline className='mb-4 w-auto'>
                        <div className='flex gap-2 mt-5 flex-wrap'>
                            {types.map((type, i) => (
                                <Pill key={i} text={type} onRemove={() => removeType(type)} />
                            ))}
                            {types.length <= 0 && (
                                <Pill text={t('cards:edit:noType')} />
                            )}
                        </div>
                        <div className='flex gap-2 md:items-end mt-4 flex-col md:flex-row'>
                            <DropdownInput
                                className='w-full lg:w-1/2'
                                deleteText={t('cards:edit:typeDeletePrompt') as string}
                                onDelete={requestDeleteType}
                                onChange={setNewType}
                                value={newType}
                                placeholder={t('cards:edit:addNewType') as string}
                                showPlaceholderOnOpen
                                id='type'
                                label={t('cards:edit:select') as string}
                                sublabel={t('cards:edit:newType') as string}
                                options={(orgTypes?.map((t) => ({ id: t.id, name: t.name })) || []) as Array<{ id: string, name: string }>}
                            />
                            <Button icon={faPlus} action={createNewType} nosubmit title={t('cards:edit:add')} />
                        </div>
                    </Card>
                    <div className='flex flex-col md:flex-row items-start gap-4'>
                        <Button icon={faFloppyDisk} iconright title={t('cards:edit:save')} />
                        {(submitted || submittedBlock) && (<Button nosubmit icon={faArrowLeft} title={isNew ? t('cards:edit:goToList') : t('cards:edit:backToList')} action={() => navigate('/' + i18n.language + '/dashboard/cards/list')} />)}
                    </div>
                </Form>
                {isBlocked ? (
                    <>
                        <Title text={t('cards:edit:unblockCard')} />
                        <div className='mb-4'>
                            {t('cards:edit:blockedFor')} {blocks![0]?.reason},
                            <span className='opacity-40'> ({moment().diff(moment(blocks![0]?.from), 'hours') < 24 ? t('cards:edit:fromToday') : `${moment(blocks![0]?.from).fromNow()}`}) </span>
                            {t('cards:edit:until').toLocaleLowerCase()}
                            <span className='opacity-40'> ({blocks![0]?.until?.includes('9999') ? t('cards:edit:dateNone') : `${formatDate(blocks![0]?.until)}`}) </span>
                        </div>
                        <Button icon={faLockOpen} className='mb-32' action={modalContext.withModal({ title: t('modal:title'), body: t('modal:unblock') }, submitUnblock)} title={t('cards:edit:unblock')} />
                    </>
                ) : (
                    <Form className='' submit={submitBlock}>
                        <Title text={t('cards:edit:blockCard')} />
                        <Input
                            submitted={submittedBlock ? true : false}
                            required
                            large
                            label={t('cards:edit:reason')}
                            id='reason' />
                        <input type='checkbox' className='ml-1 h-4 w-4 mb-6 bg-transparent' checked={infinite} onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleInfinite()) } onChange={handleInfinite} /> <span className='ml-2 opacity-70 text-xl'>{t('cards:edit:blockForever')}</span>
                        {infinite ? (
                            <input type='hidden' value='infinite' name='cardType' />
                        ) : (
                            <div className=''>
                                <CalendarInput
                                    submitted={submittedBlock ? true : false}
                                    required
                                    label={t('cards:edit:from')}
                                    id='from' />
                                <CalendarInput
                                    submitted={submittedBlock ? true : false}
                                    required
                                    label={t('cards:edit:until')}
                                    id='until' />
                            </div>
                        )}
                        <Button icon={faLock} title={t('cards:edit:block')} />
                    </Form>
                )}
            </BusyArea>
        </>
    )
}

export default CardEditPage
