import React, { useState } from 'react';
import {usePostSuggestionMutation} from '../../../../../Redux/Api/Suggestion';
import BusyArea from '../../../../Shared/BusyArea';
import Button from '../../../../Shared/Form/Button';
import StatusPopups from '../../../../Shared/Form/StatusPopups';
import Form from '../../../../Shared/Form/Form';
import Input, { FailedRequirements } from '../../../../Shared/Form/Input';
import Title from '../../../../Shared/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faAdd } from '@fortawesome/pro-solid-svg-icons';


const SuggestionCreatePage = () => {
    const [ postReleaseNode ] = usePostSuggestionMutation();

    const { t, i18n } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState('');
    const navigate = useNavigate();

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    const submit = async (data: {
        title: string,
        version: string,
        text_nl: string,
        text_en: string,
        text_de: string,
        text_fr: string,
        text_es: string,
    }) => {
        if (busy) return;
        setSubmitted(true);
        if(FailedRequirements(data, 'title', 'text_nl', 'text_en', 'text_de', 'text_fr', 'text_es')) return setError(t('genericError') || "");
        setBusy(true);
        setError('');
        setSuccessMessage('');

        if (!data.title || !data.text_nl || !data.text_en || !data.text_de || !data.text_fr || !data.text_es) {
            setError('Failed');
            setBusy(false);
            return;
        }

        for (const language of languages) {
            if ((data as any)[`text_${language}`].length > 150) {
                setError(`${t('patrickai:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }

        try {
            const outData = {
                title: data.title,
                text: {
                    text_nl: data.text_nl,
                    text_en: data.text_en,
                    text_de: data.text_de,
                    text_fr: data.text_fr,
                    text_es: data.text_es,
                },
            };

            await postReleaseNode([outData]).unwrap();

            setSuccessMessage(t('patrickai:successAdd') as string);
            setBusy(false);
            setTimeout(() => navigate(`/${i18n.language}/dashboard/admin/suggestion/list`), 1000);
        } catch (e) {
            setError(t('modal:error') as string);
            setBusy(false);
        }
    }

    return (
        <>
            <Title text={t('patrickai:createSuggestion')} textNotBold/>
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <StatusPopups setText={setError} type='error' text={error} />
                    <StatusPopups setText={setSuccessMessage} type='success' text={ successMessage } />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:url')} id='title' value='/dashboard' />
                    <h1 className='text-2xl font-bold'>{t('patrickai:suggestionText')}</h1>
                    <br />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(NL)'} id='text_nl' />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(EN)'} id='text_en' />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(DE)'} id='text_de' />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(FR)'} id='text_fr' />
                    <Input submitted={submitted ? true : false} required label={t('patrickai:text') + '(ES)'} id='text_es' />
                    <Button iconright icon={faAdd} title={t('patrickai:add')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default SuggestionCreatePage;
