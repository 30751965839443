import React, { useRef, useEffect, useState } from 'react';
import { useGetReleaseNotesQuery } from '../../../../Redux/Api/ReleaseNote';
import LoadingPage from '../../LoadingPage'
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faFileAlt, faCalendarAlt, faCodeBranch, faNewspaper } from '@fortawesome/pro-solid-svg-icons'
import IconSVG from '../../../../Assets/icon.svg?component';
import { motion } from 'framer-motion';
import { AnimatePresence } from "framer-motion";
import { useAppSelector } from '../../../../Redux/store';
import { useLazyGetOrganisationSettingsQuery } from '../../../../Redux/Api/Organisations';
import Loader from '../../../Shared/Loader';

type LanguageTextKey = `text_${string}`;

type TextResponseItem = {
  [key in LanguageTextKey]: string;
};

type LanguageContentKey = `content_${string}`;

type ContentResponseItem = {
  [key in LanguageContentKey]: string;
};

type ReleaseNoteItem = {
  type: string;
  version: string;
  date: string;
  text: TextResponseItem;
  content: ContentResponseItem;
};

type ReleaseNoteResponse = {
  data: ReleaseNoteItem[];
};

const ReleaseNotes = () => {
  const releaseNotesRef = useRef<HTMLUListElement>(null);
  const { data: ReleaseNoteResponse, refetch } = useGetReleaseNotesQuery<ReleaseNoteResponse>();
  const [releaseText, setReleaseText] = useState('');
  const [releaseContent, setReleaseContent] = useState('');
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const [isReleaseNoteHidden, setIsReleaseNoteHidden] = useState(false);
  const [displayCount, setDisplayCount] = useState(5);
  const { t, i18n } = useTranslation();
  const [isAtTop, setIsAtTop] = useState(true);
  const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery();
  const org_id = useAppSelector((s) => s.organisation.id)!;
  const [loading, setLoading] = useState(false);

  const [patrickAssistantAccess, setPatrickAssistantAccess] = useState(false);
  const [sponsorAccess, setSponsorAccess] = useState(false);
  const [notificationAccess, setNotificationAccess] = useState(false);
  const [scanAccess, setScanAccess] = useState(false);
  const [photoAccess, setPhotoAccess] = useState(false);
  const [familyCardsAccess, setFamilyCardsAccess] = useState(false);

  useEffect(() => {
    if (org_id) getOrganisationsSettings(org_id);
  }, [org_id, getOrganisationsSettings]);

  useEffect(() => {
    if (settings) {
      setNotificationAccess(!!settings.notification)
      setSponsorAccess(!!settings.sponsors)
      setPatrickAssistantAccess(!!settings.patrickAI)
      setScanAccess(!!settings.scan)
      setPhotoAccess(!!settings.photo)
      setFamilyCardsAccess(!!settings.familyCards)
    }
  }, [settings]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 250);
    return () => clearTimeout(timer);
  }, [i18n.language]);

  const today = new Date();
  const isWinter = (today.getMonth() === 11) || (today.getMonth() === 0 && today.getDate() <= 5);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY <= 65);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isSectionOpen) {
        setIsSectionOpen(false);
      }

      if (event.key === "Tab" && isSectionOpen) {
        event.preventDefault();
        setIsSectionOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSectionOpen]); 

  useEffect(() => {
    if (ReleaseNoteResponse && ReleaseNoteResponse.length > 0) {
      const highestReleaseNote = ReleaseNoteResponse[0];

      const textKey: LanguageTextKey = `text_${i18n.language}`;
      setReleaseText(highestReleaseNote.text[textKey] ?? '');

      const contentKey: LanguageContentKey = `content_${i18n.language}`;
      setReleaseContent(highestReleaseNote.content[contentKey] ?? '');
    }
  }, [i18n.language, ReleaseNoteResponse]);

  if (!ReleaseNoteResponse || ReleaseNoteResponse.length === 0 || loading) return <div className='relative lg:h-[50px]'><Loader className='absolute -top-20 lg:-top-16 left-1/2 -translate-x-1/2 py-0 text-brand' heightClass='w-7 h-7'/></div>;

  const sortedReleaseNotes = ReleaseNoteResponse;

  const highestReleaseNote = sortedReleaseNotes[0];

  const highestVersionReleaseNote = sortedReleaseNotes.find(note => note.type === 'Release note') || { version: 'N/A' };

  const lastSeenVersion = JSON.parse(localStorage.getItem('lastSeenReleaseNoteVersion') || 'null');
  const isNewReleaseNote = !lastSeenVersion || lastSeenVersion < highestReleaseNote.version;

  const parseContent = (content: string) => {
    let skipItem = false;
    return content
      .split('\n')
      .map((line, lineIndex) => {
        skipItem = false;

        const leadingSpacesMatch = line.match(/^\s+/);
        const leadingSpaces = leadingSpacesMatch
          ? leadingSpacesMatch[0]
            .split('')
            .map((_, index) => (
              <React.Fragment key={`space-${lineIndex}-${index}`}>&nbsp;</React.Fragment>
            ))
          : [];

        const filteredLine = line.replace(
          /\{(sponsor|notify|patrick|scan|family|photo) \((.*?)\)\}/g,
          (match, type, content) => {
            const formattedContent = content.replace(/ /g, '\u00A0');

            if (type === 'sponsor' && sponsorAccess) {
              return formattedContent;
            }
            if (type === 'notify' && notificationAccess) {
              return formattedContent;
            }
            if (type === 'patrick' && patrickAssistantAccess) {
              return formattedContent;
            }
            if (type === 'scan' && scanAccess) {
              return formattedContent;
            }
            if (type === 'family' && familyCardsAccess) {
              return formattedContent;
            }
            if (type === 'photo' && photoAccess) {
              return formattedContent;
            }
            skipItem = true;
            return '';
          }
        );

        if (skipItem) return null;

        if (filteredLine.trim() === '--') {
          return <hr key={`hr-${lineIndex}`} />;
        }

        const formatText = (text: string) => {
          const applyFormatting = (text: string) => {
            const formatMap = [
              { regex: /\*\*\*([^*]+?)\*\*\*/g, component: (key: string, content: string) => <strong className='text-xl' key={key}>{content}</strong> },
              { regex: /\*\*([^*]+?)\*\*/g, component: (key: string, content: string) => <strong key={key}>{content}</strong> },
              { regex: /\*([^*]+?)\*/g, component: (key: string, content: string) => <em key={key}>{content}</em> },
              { regex: /__([^_]+?)__/g, component: (key: string, content: string) => <u key={key}>{content}</u> },
            ];

            let formattedText: (string | JSX.Element)[] = [text];

            formatMap.forEach(({ regex, component }) => {
              formattedText = formattedText.flatMap((part, index) => {
                if (typeof part === 'string') {
                  const parts: (string | JSX.Element)[] = [];
                  let match;
                  let lastIndex = 0;
                  while ((match = regex.exec(part)) !== null) {
                    if (match.index > lastIndex) {
                      parts.push(part.slice(lastIndex, match.index));
                    }
                    parts.push(component(`${index}-${match.index}`, match[1]));
                    lastIndex = match.index + match[0].length;
                  }
                  if (lastIndex < part.length) {
                    parts.push(part.slice(lastIndex));
                  }
                  return parts;
                }
                return part;
              });
            });

            return formattedText;
          };

          const parts = text.split(/(\*\*\*[^*]+?\*\*\*|\*\*[^*]+?\*\*|\*[^*]+?\*|__[^_]+?__)/g);

          return parts.map((part, index) => (
            <React.Fragment key={`${lineIndex}-${index}`}>
              {typeof part === 'string' ? applyFormatting(part) : part}
            </React.Fragment>
          ));
        };

        if (filteredLine.trim().startsWith('-')) {
          const listItemContent = filteredLine.trim().slice(1).trim();
          return (
            <li className='ml-5 break-words' key={`li-${lineIndex}`}>
              {formatText(listItemContent)}
            </li>
          );
        }

        if (filteredLine.trim() === '') {
          return <div key={`line-${lineIndex}`}>&nbsp;</div>;
        }

        return (
          <div key={`line-${lineIndex}`}>
            {leadingSpaces}
            {formatText(filteredLine)}
          </div>
        );
      });
  };


  return (
    <>
      <div className='lg:h-[50px]'></div>

      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        transition={{ duration: 0.65 }}
        className={`fixed z-[102] top-0 left-0 lg:left-[320px] h-[56.9px] w-screen lg:w-[calc(100vw-320px)] group ${isAtTop ? 'pl-20' : 'pl-4'} lg:pl-4 pr-1.5 flex justify-between items-center text-white cursor-pointer shadow-lg transition duration-300 ${isNewReleaseNote && !isReleaseNoteHidden ? 'bg-green-500 hover:bg-green-600 focus:bg-green-600' : 'bg-[#0175FF] hover:bg-blue-600 focus:bg-blue-600'}`}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsSectionOpen(true);
            localStorage.setItem('lastSeenReleaseNoteVersion', JSON.stringify(highestReleaseNote.version));
          }
        }}
        onClick={() => {
          setIsSectionOpen(true);
          localStorage.setItem('lastSeenReleaseNoteVersion', JSON.stringify(highestReleaseNote.version));
        }}
      >
        <FontAwesomeIcon icon={highestReleaseNote.type !== 'News' ? faFileAlt : faNewspaper} className='text-2xl hidden sm:block' />
        <div className='flex gap-3 sm:gap-4 group-focus:scale-105 group-hover:scale-105 transition duration-300'>
          {highestReleaseNote.type !== 'News' && (<h3 className='whitespace-nowrap hidden md:block'><strong> {t('releasenote:version')}</strong> {highestReleaseNote.version}</h3>)}
          <p className='whitespace-nowrap hidden sm:block'><strong> {t('releasenote:date')}</strong> {moment(highestReleaseNote.date).format('DD-MM-YYYY')}</p>
          <p className='whitespace-nowrap hidden sm:block'><strong>-</strong></p>
          <p className='whitespace-nowrap font-bold relative'>
            {parseContent(releaseText)}
            {isNewReleaseNote && (
              <span className="absolute top-[-5px] right-[-15px] bg-[#0175FF] text-white text-xs font-bold px-2 py-2 rounded-full animate-ping" />
            )}
          </p>

        </div>
        <div className='flex items-center justify-center'>
          <div
          tabIndex={isNewReleaseNote && !isReleaseNoteHidden ? 0 : -1}
            className={`flex items-center justify-center focus:bg-white hover:bg-white w-[40px] h-[40px] rounded-full transition duration-300 focus:text-green-500 hover:text-green-500 ${isNewReleaseNote && !isReleaseNoteHidden ? '' : 'hidden'}`}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                localStorage.setItem('lastSeenReleaseNoteVersion', JSON.stringify(highestReleaseNote.version));
                setIsReleaseNoteHidden(true);
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
              localStorage.setItem('lastSeenReleaseNoteVersion', JSON.stringify(highestReleaseNote.version));
              setIsReleaseNoteHidden(true);
            }}
          >
            <FontAwesomeIcon icon={faTimes} className='text-2xl transition duration-300' />
          </div>
          {isWinter && (
            <div className='w-[45px] h-[45px] ml-2' />
          )}
        </div>

      </motion.div>

      <AnimatePresence>
        {!lastSeenVersion && (
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -100 }}
            transition={{ duration: 1 }}
            className='absolute top-[52px] left-[55px] lg:left-[30px] bg-[#0175FF] text-white text-xs font-bold px-3 py-2 rounded-b-xl shadow-lg'
          >
            {t('releasenote:clickToRead')}
          </motion.div>
        )}
      </AnimatePresence>

      {isSectionOpen && (<style>{`body { overflow: hidden; }`}</style>)}

      <AnimatePresence>
        {isSectionOpen && (
          <>
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed top-0 right-0 bg-black/50 w-screen h-full z-[999999999999999999] flex flex-col items-center overflow-y-auto overflow-x-hidden pt-20 cursor-pointer backdrop-filter backdrop-blur-[2px]"
              onClick={() => setIsSectionOpen(false)}
            >
              <motion.div
                initial={{ marginTop: '100px' }}
                animate={{ marginTop: '0px' }}
                exit={{ marginTop: '100px' }}
                className="bg-gradient-to-b from-white to-gray-100 w-[calc(100vw-40px)] lg:w-[calc(100vw-321px)] p-8 md:p-12 rounded-3xl shadow-2xl flex flex-col mb-10 cursor-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <header className="mb-4 border-b-2 border-gray-400 pb-4 flex justify-between items-start flex-col md:flex-row">
                  <h1 className="ml-3 text-2xl md:text-[45px] font-extrabold gap-2 flex items-center text-gray-900">
                    <IconSVG className="mr-3 w-16 h-16 text-brand" />
                    {t('releasenote:title')}
                  </h1>
                  <p className="mt-1 md:mt-4 text-gray-600 mr-3">
                    <FontAwesomeIcon icon={faCodeBranch} className="mr-2" />
                    {t('releasenote:version')} {highestVersionReleaseNote.version}
                  </p>
                </header>

                {sortedReleaseNotes.slice(0, displayCount).map((releaseNote, index) => {
                  const textKey = `text_${i18n.language}`;
                  const contentKey = `content_${i18n.language}`;

                  const textValue = releaseNote.text ? (releaseNote.text as { [key: string]: string })[textKey] : null;
                  const contentValue = releaseNote.content ? (releaseNote.content as { [key: string]: string })[contentKey] : null;

                  return (
                    <article key={index} className="p-6 border-b border-gray-300 mb-4">
                      {textValue && (
                        <h1 className="text-3xl font-bold text-gray-800 flex items-center hyphens-auto word-break">
                          <FontAwesomeIcon icon={releaseNote.type !== 'News' ? faFileAlt : faNewspaper} className="text-2xl mr-2" />
                          {parseContent(textValue)}
                        </h1>
                      )}

                      <h2 className="text-lg font-medium text-gray-600 mt-3 mb-4 flex flex-col sm:flex-row items-start justify-start">
                        {releaseNote.type !== 'News' && (
                          <>
                            <span>
                              <FontAwesomeIcon icon={faCodeBranch} className="mr-2" />
                              {t('releasenote:version')}: {releaseNote.version}
                            </span>
                            <span className="hidden sm:block mx-2">-</span>
                          </>
                        )}
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                          {t('releasenote:releaseDate')}: {moment(releaseNote.date).format('DD-MM-YYYY')}
                        </span>
                      </h2>

                      {contentValue && (
                        <p className="ml-2 text-gray-700 leading-relaxed break-words">
                          {parseContent(contentValue)}
                        </p>
                      )}
                    </article>
                  );
                })}

                {displayCount < sortedReleaseNotes.length && (
                  <button
                    className="mt-6 px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-lg shadow-md hover:from-blue-700 hover:to-purple-700 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setDisplayCount(displayCount + 5)}
                  >
                    {t('releasenote:loadMore')}
                  </button>
                )}
              </motion.div>
            </motion.section>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => setIsSectionOpen(false)} className="fixed z-[999999999999999999] top-3 right-3 bg-black/50 w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer">
              <FontAwesomeIcon icon={faTimes} className="text-white text-3xl" />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ReleaseNotes;
