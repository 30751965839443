import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '../Shared/Form/Button'
import Title from '../Shared/Title'
import { useTranslation } from 'react-i18next';

export interface notFoundProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    fullHeight?: boolean;
}

const Page404 = (props: notFoundProps) => {
    const { fullHeight, ...restProps } = props;

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const handleNavigateBack = () => {
        navigate(-1)
    }

    return (
        <>
            <div className={`flex w-full ${fullHeight ? 'h-screen' : 'h-full'} items-center justify-center`}>
                <div className='flex flex-col p-20'>
                    <Title text="404" subtitle={t("404") || " "} />

                    <Button action={handleNavigateBack} icon={faArrowLeft} title={t("back")} />
                </div>
            </div>
        </>
    )
}

export default Page404
