import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useUploadMutation } from '../../../../Redux/Api/Api';
import { useAddLogoMutation, usePutThemeMutation, useGetOrganisationQuery, useLazyGetOrganisationSettingsQuery } from '../../../../Redux/Api/Organisations';
import { putThemeLocal } from '../../../../Redux/State/Organisation'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import ColorPicker from '../../../Shared/Form/ColorPicker'
import FilePicker from '../../../Shared/Form/FilePicker'
import Form from '../../../Shared/Form/Form'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import { useTranslation } from "react-i18next";
import Input from '../../../Shared/Form/Input'
import validator from 'validator';
import MobileApp from "../../../Shared/App/MobileApp";
import MyCard from "../../../Shared/App/MyCard";

const ThemePage = () => {

    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { t, i18n } = useTranslation()

    const dispatch = useAppDispatch()

    const [putTheme] = usePutThemeMutation()
    const [uploadFile] = useUploadMutation()
    const [addLogo] = useAddLogoMutation()
    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()

    const [busy, setBusy] = useState(false)
    const [tint, setTint] = useState('#E6171A')
    const [lighterTint, setLighterTint] = useState('#ed4245')
    const [fileBlob, setFileBlob] = useState('')
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('')

    const [organisationEmail, setOrganisationEmail] = useState('');

    const { data, isError } = useGetOrganisationQuery({ id: org_id! }, {
        skip: !org_id,
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if (!theme) return

        setTint(theme.colors.tint)
        setLighterTint(theme.colors.lighterTint)

        setFileBlob(theme.icon)
    }, [theme])


    useEffect(() => {
        if (org_id)
            getOrganisationsSettings(org_id)

        if (settings) {
            setOrganisationEmail(settings.organisationEmail)
        }
    }, [org_id, settings])

    const isEmailValid = (email: string) => {
        return validator.isEmail(email);
    };

    useEffect(() => {
        if (sessionStorage.getItem('successMessage')) {
            setSuccessMessage(sessionStorage.getItem('successMessage')!);
            sessionStorage.removeItem('successMessage');
        }
    }, [org_id])

    const submit = async ({ logo, organisationEmail, }: { logo: File, organisationEmail: string, }) => {
        if (busy) return
        setSubmitted(true)
        setBusy(true)
        setError('')
        setSuccessMessage('')
        if (isEmailValid(organisationEmail)) {
            try {
                const settings = {
                    background: '',
                    normal: '',
                    border: '',
                    inactive: '',
                    text: '',
                    lighterText: '',
                    tint,
                    lighterTint,
                    organisationEmail
                }

                await putTheme({
                    id: org_id,
                    settings
                }).unwrap()

                if (logo) {
                    const upload_id = await uploadFile({
                        file: logo
                    }).unwrap()

                    await addLogo({
                        organisation_id: org_id,
                        upload_id
                    })
                }

                if (logo) {
                    sessionStorage.setItem('successMessage', t('organisation:theme:success'))
                    window.location.reload();
                } else {
                    dispatch(putThemeLocal(settings))
                    setSuccessMessage(t('organisation:theme:success') as string)
                }
            } catch (e) {
                let err = e as Hydra.Error
                setError(err['hydra:description'])
                setBusy(false)
            }
        } else {
            setError(t('organisation:theme:validEmail') || '');

        }

        setTimeout(() => setBusy(false), 200)
    }

    if (!theme) return <LoadingPage />
    if (!data || !settings) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('organisation:theme:title')} subtitle={t('organisation:theme:subtitle') as string} textNotBold />
                        <StatusPopups setText={setError} type='error' text={error} />
                        <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />

                        <div className='flex flex-col mb-[-40px]'>
                            <p style={{ whiteSpace: "pre-line" }}>
                                {t('organisation:theme:description')}
                            </p>
                        </div>
                        <Form submit={submit}>
                            <Input
                                className='mt-[4rem]'
                                submitted={submitted ? true : false}
                                required
                                label={t('organisation:theme:replyEmail')}
                                type='email'
                                initial={data.settings?.organisationEmail || 'support@digipas.app'}
                                id='organisationEmail' />

                            <FilePicker style={{ marginTop: '-15px' }} id='logo' label='Logo' noBottomMargin onChangeBlob={setFileBlob} accept={['image/*']} />

                            <ColorPicker initial='#E6171A' color={tint} onChangeReactive={setTint} id='tint' label={t('organisation:theme:primaryColor')} />
                            <ColorPicker initial='#ed4245' color={lighterTint} onChangeReactive={setLighterTint} id='lighterTint' label={t('organisation:theme:secondaryColor')} />

                            <Button icon={faFloppyDisk} iconright title={t('organisation:theme:save')} />
                        </Form>
                    </div>


                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        <MobileApp
                            allowMovement
                            activeItem="mycard"
                            customIcon={fileBlob}
                            customColor
                            customColorPrimary={tint}
                            customColorSecondary={lighterTint}
                        >
                            <MyCard />
                        </MobileApp>
                    </div>
                </div>
            </BusyArea>
        </>
    )
}

export default ThemePage
