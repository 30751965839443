import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import news1 from '../../../Assets/landingpageImage.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRightLong, faCalendarLines } from "@fortawesome/pro-light-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { useAppSelector } from "../../../Redux/store";

interface NewsProps {
    customNews?: boolean
    customTitle?: string
    customImg?: string
    customText?: string
    customLink?: string
    costumDate?: Date
}

const News: React.FC<NewsProps> = ({ customNews, customTitle, customImg, customText, customLink, costumDate }) => {
    const { t, i18n } = useTranslation();
    const today = new Date();
    const newsDate = new Date('2024-04-05');
    const [showNews, setShowNews] = React.useState(false);
    const [fileBlob, setFileBlob] = useState<string>('');
    const theme = useAppSelector((s) => s.organisation.settings?.theme);

    const formatDate = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(i18n.language === 'en' ? 'en-GB' : i18n.language, options);
    };

    useEffect(() => {
        if (!theme) return;
        setFileBlob(theme.icon);
    }, [theme]);

    return (
        <>
            <div className="relative px-[20px] pt-[20px]">

                <motion.div animate={{ scale: showNews ? 1.05 : 1 }} initial={{ scale: 1 }} transition={{ duration: 0.2, ease: "easeInOut" }} style={{ transformOrigin: 'center' }}>
                    <div className="font-app-bold text-[24px] ml-[10px]">{t('cards:app:newsTitle')}</div>

                    <div className="flex flex-col gap-[10px] overflow-y-auto no-scrollbar h-[589px]">

                        {customNews ? (
                            <div
                                tabIndex={0}
                                onKeyDown={(e) => e.key === 'Enter' && (customLink ? window.open(customLink, '_blank') : setShowNews(true))}
                                onClick={() => { customLink ? window.open(customLink, '_blank') : setShowNews(true) }}
                                className="mb-[30px] relative bg-white rounded-[15px] flex flex-row p-[20px] cursor-pointer">

                                <img src={customImg || fileBlob} className="min-h-[100px] min-w-[100px] max-w-[100px] max-h-[100px] rounded-[5px] bg-black object-cover" />

                                <div className="mt-[5px] mx-[20px] justify-between overflow-hidden">

                                    <div className="flex flex-row items-center text-[#9B9B9B]">
                                        <FontAwesomeIcon icon={faCalendarLines} className="text-[15px]" />
                                        <p className="text-[12px] ml-[6px] font-app-bold">{formatDate(costumDate || today)}</p>
                                    </div>
                                    <h1 className="mt-[10px] text-[16px] font-app-bold leading-[19px] min-w-[160px] max-w-[160px] overflow-hidden" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>{customTitle}</h1>
                                    <FontAwesomeIcon icon={faArrowRightLong} className="absolute right-[15px] bottom-[20px] text-[12px]" />
                                </div>
                                <div className="absolute -bottom-[25px] left-1/2 -translate-x-1/2 text-black text-[14px] whitespace-nowrap opacity-50">
                                    {customLink ? t('cards:app:openLink') : t('cards:app:openNews')}
                                </div>
                            </div>
                        ) : (
                            <div className="relative bg-white rounded-[15px] flex flex-row p-[20px] cursor-not-allowed">
                                <img src={news1} className="min-h-[100px] min-w-[100px] max-w-[100px] max-h-[100px] rounded-[5px] bg-black object-cover" />

                                <div className="mt-[5px] mx-[20px] justify-between overflow-hidden">

                                    <div className="flex flex-row items-center text-[#9B9B9B]">
                                        <FontAwesomeIcon icon={faCalendarLines} className="text-[15px]" />
                                        <p className="text-[12px] ml-[6px] font-app-bold">{formatDate(newsDate)}</p>
                                    </div>
                                    <h1 className="mt-[10px] text-[16px] font-app-bold leading-[19px] min-w-[160px] max-w-[160px] overflow-hidden" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>Digipas.app</h1>
                                    <FontAwesomeIcon icon={faArrowRightLong} className="absolute right-[15px] bottom-[20px] text-[12px]" />
                                </div>
                            </div>
                        )}

                    </div>
                </motion.div>
                <AnimatePresence>
                    {showNews && (
                        <motion.div initial={{ opacity: 0, scale: 0.95 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.95 }} transition={{ duration: 0.2, ease: "easeInOut" }} className="bg-[#F6F8FA] absolute top-0 left-0 right-0 bottom-0 overflow-y-auto no-scrollbar">
                            <div className="flex flex-row items-center text-[#9B9B9B] ml-[30px] my-[20px]">
                                <FontAwesomeIcon icon={faCalendarLines} className="text-[15px]" />
                                <p className="text-[12px] ml-[6px]">{formatDate(costumDate || today)}</p>
                            </div>

                            <h1 className="mx-[30px] text-[24px] font-app-bold">{customTitle}</h1>

                            <img src={customImg || fileBlob} className="h-[200px] w-full bg-black object-cover my-[20px]" />

                            <article className="p-[30px]">
                                <div className="app-news-style" dangerouslySetInnerHTML={{ __html: customText || '' }} />
                            </article>

                            <div tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && setShowNews(false)} onClick={() => { setShowNews(false) }} className="cursor-pointer flex flex-row items-center justify-center text-[#9B9B9B] p-[30px] gap-[15px] font-normal">
                                <FontAwesomeIcon icon={faArrowLeft} className="text-[15px] text-accent" />
                                Terug
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>


        </>
    );
};

export default News;
