import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Title from '../Title';
import Button from './Button';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onClose: (confirmed: boolean) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, message, onClose }) => {
    const { t, i18n } = useTranslation();
    const [activeButton, setActiveButton] = useState<'yes' | 'no' | ''>('');

    const formatMessage = (msg: string) => {
        const dateRegex = /\b\d{2}-\d{2}-\d{4}\b/;
        const cleanedMessage = msg.replace(/\(.*?\)/g, '').trim();

        return cleanedMessage.replace(dateRegex, (match) => {
            const [day, month, year] = match.split('-');
            const dateObj = new Date(`${year}-${month}-${day}`);
            const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
            const formattedDate = dateObj.toLocaleDateString(i18n.language === 'en' ? 'en-GB' : i18n.language, options);

            return `<strong class="whitespace-nowrap">${formattedDate}</strong>`;
        });
    };

    const toggleActiveButton = (direction: 'left' | 'right') => {
        ;
        setActiveButton((prev) => (direction === 'left' ? (prev === '' ? 'no' : (prev === 'yes' ? 'no' : 'yes')) : (prev === '' ? 'yes' : (prev === 'no' ? 'yes' : 'no'))));
    };

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            onClose(false);
            setActiveButton('');
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
            toggleActiveButton(e.key === 'ArrowRight' ? 'right' : 'left');
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (activeButton === 'yes' || activeButton === 'no') {
                const shouldClose = activeButton === 'yes';
                onClose(shouldClose);
                setActiveButton('');
            }
        }  else if (e.key === 'Tab') {
            e.preventDefault();
            toggleActiveButton('right');
        }
    }, [onClose, activeButton]);

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, handleKeyDown]);

    return (
        <AnimatePresence>
            {open && (
                <div className="fixed flex items-center justify-center left-0 top-0 w-full h-screen z-[1000000]">

                    <motion.div
                        initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                        exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        className='flex flex-col z-50 max-w-[90vw] md:max-w-[50vw] rounded-[0.4rem] bg-light-200'>
                        <div className='flex flex-col m-20'>
                            <Title text={title + '!'} />
                            <div className='mt-4'>
                                <p dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
                            </div>
                            <div className='flex gap-4 mt-8'>
                                <Button
                                    icon={faCheck}
                                    active={activeButton === 'yes'}
                                    nosubmit
                                    title={t('cards:list:yes')}
                                    action={() => { onClose(true); setActiveButton(''); }}
                                />
                                <Button
                                    icon={faXmark}
                                    active={activeButton === 'no'}
                                    secondary
                                    nosubmit
                                    title={t('cards:list:no')}
                                    action={() => { onClose(false); setActiveButton(''); }}
                                />
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        key="background-div"
                        onClick={() => onClose(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        className="fixed left-0 top-0 w-screen h-screen bg-black"
                    />
                </div>
            )}
        </AnimatePresence>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
