import React, { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'

import DigipasLogoWidePNG from '../../../../Assets/digipaslogowide.png'
import { JWT } from '../../../../Utils/JWT'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import Title from '../../../Shared/Title'
import { useTranslation } from 'react-i18next';

const SuccessPage = () => {

    const organisation_name = JWT.read<string>('organisation_name')

    const [ { width, height }, setSize ] = useState({ width: 0, height: 0 })
    const ref = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        if(!ref.current) return

        const node = ref.current.closest('#node_MAIN')

        if(!node) return

        const resize = () => setSize(node!.getBoundingClientRect())

        setSize(node.getBoundingClientRect())


        node.addEventListener('resize', resize)

        window.addEventListener('resize', resize)

        return () => {
            node.removeEventListener('resize', resize)
            window.removeEventListener('resize', resize)
        }
    }, [ ref ])

    return (
        <div ref={ ref } className='flex flex-col flex-grow h-full items-center justify-center'>
            <Confetti
                className='absolute top-0 right-0 z-[2]'
                width={ width }
                height={ height }/>
            <Card rounded className='w-11/12 md:w-1/2 min-h-[50vh] gap-10 z-[3]' free>
                <img className='w-8/12 mx-auto py-10' src={ DigipasLogoWidePNG } />
                <div className='flex flex-col mx-10 flex-grow'>
                    <Title text={t ("wizzard:welcome")} />
                    <div>{t ("wizzard:welcomeText")}</div>
                    <div className='flex flex-col mt-auto'>
                        <div>{t ("wizzard:goto")}</div>
                        <div className='flex my-4 flex-wrap gap-2'>
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard`) } title={t ("wizzard:overview")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/cards`) } title={t ("wizzard:cards")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/organisation/theme`) } title={t ("wizzard:theme")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/organisation/news`) } title={t ("wizzard:news")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/organisation/socials`) } title={t ("wizzard:socials")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/notify`) } title={t ("wizzard:notify")} />
                            <Button className='flex-1 whitespace-nowrap' action={ () => navigate(`/${i18n.language}/dashboard/users`) } title={t ("wizzard:users")} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SuccessPage
