import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { useLazyGetUsersQuery } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store';
import { useTranslation } from "react-i18next";
import {
    useLazyGetCheckedInScansQuery,
    useLazyGetCheckOutScansQuery,
    useLazyGetCheckScansQuery
} from '../../../../Redux/Api/Organisations';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCrown, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { JWT } from '../../../../Utils/JWT';
import LoadingPage from "../../LoadingPage";

export interface UserScan {
    todate: Date;
    fromdate: Date;
    loading: boolean;
}

const UserScan = (props: UserScan) => {
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const { t, i18n } = useTranslation();
    const user_id = JWT.read<string>('sub');
    const isSocialUser = JWT.read<Array<string>>('roles')?.includes('ROLE_SOCIAL');

    const [getUsers, { data: users }] = useLazyGetUsersQuery();

    const [getCheckedInScans, { data: checkedInScans }] = useLazyGetCheckedInScansQuery();
    const [getCheckOutScans, { data: checkOutScans }] = useLazyGetCheckOutScansQuery();
    const [getCheckScans, { data: checkScans }] = useLazyGetCheckScansQuery();
    const navigate = useNavigate();

    let rows = [];
    let checkTopUsers: string[] = [];
    let checkinTopUsers: string[] = [];
    let checkoutTopUsers: string[] = [];
    let checkMax = 0;
    let checkinMax = 0;
    let checkoutMax = 0;

    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number);
        return date.toISOString().slice(0, 10);
    };

    useEffect(() => {
        if (org_id) {
            getUsers(org_id);
        }
    }, [org_id]);

    useEffect(() => {
        if (org_id) {
            const fromDate = formatDateToYMD(props.fromdate, 2);
            const toDate = formatDateToYMD(props.todate, 2);
            getCheckedInScans({ org_id, fromDate, toDate });
            getCheckOutScans({ org_id, fromDate, toDate });
            getCheckScans({ org_id, fromDate, toDate });
        }
    }, [org_id, props.fromdate, props.todate]);

    if (users !== undefined) {
        //@ts-ignore
        rows = users['hydra:member'].map((user: any) => {
            const checkCount = Scans(user.id, 'check');
            const checkinCount = Scans(user.id, 'checkin');
            const checkoutCount = Scans(user.id, 'checkout');
            const totalScans = checkCount + checkinCount + checkoutCount;

            return {
                ...user,
                checkCount,
                checkinCount,
                checkoutCount,
                totalScans,
            };
        });

        rows.sort((a: any, b: any) => b.totalScans - a.totalScans);

        checkMax = Math.max(...rows.map((row: any) => row.checkCount));
        checkinMax = Math.max(...rows.map((row: any) => row.checkinCount));
        checkoutMax = Math.max(...rows.map((row: any) => row.checkoutCount));

        checkTopUsers = rows.filter((row: any) => row.checkCount === checkMax).map((row: any) => row.id);
        checkinTopUsers = rows.filter((row: any) => row.checkinCount === checkinMax).map((row: any) => row.id);
        checkoutTopUsers = rows.filter((row: any) => row.checkoutCount === checkoutMax).map((row: any) => row.id);
    }


    function Scans(id: string, action: string) {
        let num = 0;

        if (action === 'check') {
            if (checkScans !== undefined) {
                const scans = Object.values(checkScans);

                for (let i = 0; i < scans.length; i++) {
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1;
                    }
                }
            }
        } else if (action === 'checkin') {
            if (checkedInScans !== undefined) {
                const scans = Object.values(checkedInScans);

                for (let i = 0; i < scans.length; i++) {
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1;
                    }
                }
            }
        } else if (action === 'checkout') {
            if (checkOutScans !== undefined) {
                const scans = Object.values(checkOutScans);

                for (let i = 0; i < scans.length; i++) {
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1;
                    }
                }
            }
        }
        return num;
    }

    if (!users || !checkedInScans || !checkOutScans || !checkScans || props.loading) {
        return (
            <div className="border border-[#D7E3F2] rounded-[0.4rem] overflow-hidden">
                <div className="overflow-y-hidden bg-white min-h-[450px] max-h-[450px]">
                    <div className='border-b border-[#D7E3F2] bg-black/[1%] flex justify-between items-center'>
                        <h1 className="font-bold px-5 py-4 text-md">{t('overview:numberScans')}</h1>
                        <FontAwesomeIcon className='mr-5 text-xl opacity-[25%]' icon={faUsers} />
                    </div>
                    <div className='overflow-hidden h-[394px] flex items-center justify-center'>
                        <FontAwesomeIcon className='mr-5 text-xl opacity-[25%] animate-spin' icon={faSpinner} />
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="border border-[#D7E3F2] rounded-[0.4rem] overflow-hidden">
            <div className="overflow-y-hidden bg-white min-h-[450px] max-h-[450px]">
                <div className='border-b border-[#D7E3F2] bg-black/[1%] flex justify-between items-center'>
                    <h1 className="font-bold px-5 py-4 text-md">{t('overview:numberScans')}</h1>
                    <FontAwesomeIcon className='mr-5 text-xl opacity-[25%]' icon={faUsers} />
                </div>
                <div className='overflow-auto max-h-[394px]'>
                    <TableContainer>
                        <Table aria-label="caption table">
                            <TableHead className="bg-slate-100">
                                <TableRow className="text-sm">
                                    <TableCell
                                        className="px-3 md:px-6 font-bold text-[12px] md:text-[16px] font-inter">{t('overview:ScanAccount')}</TableCell>
                                    <TableCell className="text-center font-bold text-[12px] md:text-[16px] font-inter">
                                        <div className="flex flex-wrap items-center justify-center gap-1">
                                            <div className="min-w-2.5 min-h-2.5 bg-[#0175FF] rounded-full"></div>
                                            {t('overview:check')}
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-center font-bold text-[12px] md:text-[16px] font-inter">
                                        <div className="flex flex-wrap items-center justify-center gap-1">
                                            <div className="min-w-2.5 min-h-2.5 bg-[#00C45E] rounded-full"></div>
                                            {t('overview:checkin')}
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-center font-bold text-[12px] md:text-[16px] font-inter">
                                        <div className="flex flex-wrap items-center justify-center gap-1">
                                            <div className="min-w-2.5 min-h-2.5 bg-[#FF0058] rounded-full"></div>
                                            {t('overview:scanout')}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any) => (
                                    <TableRow tabIndex={0} key={row.id} onKeyDown={(e) => e.key === 'Enter' && !isSocialUser ? navigate(`/${i18n.language}/dashboard/users/edit/${row.id}`) : undefined} onClick={() => !isSocialUser ? navigate(`/${i18n.language}/dashboard/users/edit/${row.id}`) : undefined} className={`border-b border-[#D7E3F2] group ${!isSocialUser && 'focus:bg-slate-50 hover:bg-slate-50 cursor-pointer'}`}>
                                        <TableCell className="relative text-[12px] md:text-[16px] font-inter text-[#5B6C79] max-w-[10px] sm:max-w-[150px] lg:max-w-[125px] 2xl:max-w-[200px] z-[1]">
                                            <h1 className='max-w-full overflow-hidden text-ellipsis whitespace-nowrap'>{row.firstname} {row.lastname} {row.id === user_id && <span className='lowercase'>({t("patrick:you")})</span>}</h1>
                                            <h1 className={`absolute top-[16px] ${isSocialUser ? 'bg-white' : 'bg-slate-50'} hidden group-hover:block group-focus:block pr-1 whitespace-nowrap`}><span className='text-black'>{row.firstname} {row.lastname} {row.id === user_id && <span className='lowercase'> ({t("patrick:you")})</span>}</span></h1>
                                        </TableCell>
                                        <TableCell className="relative text-center text-[12px] md:text-[16px] font-inter text-[#5B6C79]">
                                            {Scans(row.id, 'check')}
                                            {checkMax > 0 && checkTopUsers.includes(row.id) && (
                                                <FontAwesomeIcon className="absolute top-0.5 left-[50%] -translate-x-[50%] text-[#FFD700]" icon={faCrown} />
                                            )}
                                        </TableCell>
                                        <TableCell className="relative text-center text-[12px] md:text-[16px] font-inter text-[#5B6C79]">
                                            {Scans(row.id, 'checkin')}
                                            {checkinMax > 0 && checkinTopUsers.includes(row.id) && (
                                                <FontAwesomeIcon className="absolute top-0.5 left-[50%] -translate-x-[50%] text-[#FFD700]" icon={faCrown} />
                                            )}
                                        </TableCell>
                                        <TableCell className="relative text-center text-[12px] md:text-[16px] font-inter text-[#5B6C79]">
                                            {Scans(row.id, 'checkout')}
                                            {checkoutMax > 0 && checkoutTopUsers.includes(row.id) && (
                                                <FontAwesomeIcon className="absolute top-0.5 left-[50%] -translate-x-[50%] text-[#FFD700]" icon={faCrown} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default UserScan;
