import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    useGetBlocksQuery,
    useGetCardQuery,
    usePostActivationLinkEmailMutation,
    usePostBlockMutation,
    usePostUnblockMutation,
    usePutCardMutation,
} from '../../../../Redux/Api/Cards';
import { useGetTypeQuery, usePostTypeMutation } from '../../../../Redux/Api/Types';
import { all, isInt, isPositive, max, min } from '../../../../Utils/InValidation'
import { CardEditItem, CardResponseItem } from '../../../../Redux/Api/Request/Cards'
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import CalendarInput from '../../../Shared/Form/CalendarInput'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Pill from '../../../Shared/Form/Pill'
import DropdownInput from '../../../Shared/Form/DropdownInput'
import { Hydra } from '../../../../Utils/Hydra'
import Constants from '../../../../Constants'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import Page404 from '../../Page404'
import Switch from "../../../Shared/Form/Switch";
import { useLazyGetTypeDataQuery } from "../../../../Redux/Api/Organisations";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../Shared/Form/FilePicker";
import { useGetNewsItemQuery, usePutNewsMutation, useAddNewsLogoMutation } from "../../../../Redux/Api/News";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { faArrowLeft, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import MobileApp from "../../../Shared/App/MobileApp";
import News from "../../../Shared/App/News";

const EditNewsPage = () => {
    const modalContext = useContext(ModalContext)
    const navigate = useNavigate()
    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [busy, setBusy] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [fileBlob, setFileBlob] = useState('');
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');

    const [putNews] = usePutNewsMutation();
    const [addNewsLogo] = useAddNewsLogoMutation();
    const [uploadFile] = useUploadMutation();

    const handleText = (e: any) => {
        setText(e.target.value)
    }

    if (!id) return <Page404 />

    const org_id = useAppSelector((s) => s.organisation.id)!


    const { data: news, refetch } = useGetNewsItemQuery({
        id: id!
    })

    const newsImage = news?.image
    const newsID = news?.id

    useEffect(() => {
        if (org_id) {
            refetch();
        }
    }, [org_id, refetch]);

    const submit = async (news: {
        title: string,
        image: File,
        url: string
    }) => {
        setSubmitted(true)
        if (FailedRequirements(news, 'title')) return setError(t('modal:error') as string)
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        if (text.length > 49000) {
            setError(t('organisation:news:tooLong') as string);
            setBusy(false);
            return;
        }

        const outData = {
            title: news.title,
            text: text,
            url: news.url
        }

        try {
            let upload_id = ''
            if (news.image && (news.image.name || news.image.name.trim() !== '')) {
                upload_id = await uploadFile({
                    file: news.image
                }).unwrap();
            }

            await putNews({
                id,
                ...outData
            }).unwrap()

            if (news.image && (news.image.name || news.image.name.trim() !== '')) {
                await addNewsLogo({
                    news_id: id.toString(),
                    upload_id
                });
            }

            sessionStorage.setItem('successMessage', t('organisation:news:successEdit') as string);
            refetch();
            navigate(`/${i18n.language}/dashboard/news/list`);
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 300)
    }

    useEffect(() => {
        if (!news) return
        setText(news.text)
        setTitle(news.title)
        setUrl(news.url)
        setFileBlob(news.image)
    }, [news])

    if (!news) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('organisation:news:title')} subtitle={t('organisation:news:editNews') as string} textNotBold />
                        <StatusPopups setText={setError} type='error' text={error} />
                        <StatusPopups setText={setSuccessMessage} type='success' text={successMessage} />
                        <Form submit={submit}>
                            <Input
                                submitted={submitted ? true : false}
                                required
                                invalidator={all(min(2), max(255))}
                                onChange={(e) => { setTitle((e.target as HTMLInputElement).value); }}
                                label={t('organisation:news:tableTitle')}
                                initial={news.title}
                                id='title' />
                            <FilePicker submitted={submitted ? true : false} id='image' label={t('organisation:news:image')} onChangeBlob={setFileBlob} accept={['image/*']} initial={news.image} />
                            <Input
                                sublabel={t('organisation:news:urlOptional') as string}
                                invalidator={all(min(0), max(255))}
                                label={t('organisation:news:url')}
                                onChange={(e) => { setUrl((e.target as HTMLInputElement).value); }}
                                initial={news.url}
                                placeholder="https://"
                                type='url'
                                id='url'
                                onBlur={() => { const saveButton = document.getElementById('Save'); if (saveButton) { saveButton.focus(); } }} />
                            <label>{t('organisation:news:text')} <span className='opacity-70 text-sm'>({t('cards:list:optional')})</span></label>
                            <ReactQuill
                                value={text}
                                onChange={setText}
                                id='text'
                                className={'flex flex-col mb-14 bg-transparent p-1 border-black border-opacity-10 w-full h-80'}
                            />
                            <div className='flex flex-wrap gap-4'>
                                <Button id='Save' icon={faFloppyDisk} iconright title={t('organisation:news:save')} />
                                <Button nosubmit icon={faArrowLeft} action={() => navigate(`/${i18n.language}/dashboard/news/list`)} title={t('back')} />
                            </div>
                        </Form>
                    </div>


                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        <MobileApp activeItem="news">
                            <News
                                customNews
                                customTitle={title}
                                customImg={fileBlob}
                                customText={text}
                                customLink={url}
                                costumDate={new Date(news.date)}
                            />
                        </MobileApp>
                    </div>
                </div>
            </BusyArea>
        </>
    )
}

export default EditNewsPage
