import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { useChat } from './ChatContext';
import Constants from '../../../Constants';
import bot from '../../../Assets/patrick.png';
import close from '../../../Assets/close.svg';
import ellipse from '../../../Assets/ellipse.svg';
import ellipseRed from '../../../Assets/ellipseRed.svg';
import wave from '../../../Assets/wave.svg';
import chat from '../../../Assets/chat.svg';
import paperclip from '../../../Assets/paperclip.svg';
import send from '../../../Assets/send.svg';
import IconSVG from '../../../Assets/icon.svg?component';
import santa from '../../../Assets/santa.png';
import ChatboxSuggestions from './ChatboxSuggestions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion'
import { useAppSelector } from '../../../Redux/store';
import { useChatMessageMutation, useUploadFileMutation, useVerifyTemplateMutation, useCheckHealthMutation } from '../../../Redux/Api/PatrickAiApi';
import { JWT } from '../../../Utils/JWT';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type Message = {
    content: string;
    sender: string;
    time: string;
};


type Messages = Message[];

const ChatBox: React.FC = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { isChatOpen, toggleChat, messages, addMessage, setMessages } = useChat();
    const [message, setMessage] = useState('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [introVisible, setIntroVisible] = useState(() => {
        const storedValue = localStorage.getItem('introVisible');
        return storedValue ? JSON.parse(storedValue) : true;
    });
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(() => {
        const storedValue = localStorage.getItem('showSuggestions');
        return storedValue ? JSON.parse(storedValue) : true;
    });
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const [firstOpen, setfirstOpen] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);
    const [isWaveCooldown, setIsWaveCooldown] = useState(false);
    const [firstOpenIntro, setfirstOpenIntro] = useState(false);
    const [isCooldownIntro, setIsCooldownIntro] = useState(false);
    const isInputNotEmpty = message.trim().length > 0;
    const [atBottom, setAtBottom] = useState(false);
    const [canScroll, setCanScroll] = useState(false);
    const [chatMessage] = useChatMessageMutation();
    const [verifyTemplate] = useVerifyTemplateMutation();
    const [checkHealth] = useCheckHealthMutation();
    const BOTTOM_THRESHOLD = 200;
    const [uploadFile] = useUploadFileMutation();
    const isSocialUser = JWT.read<Array<string>>('roles')?.includes('ROLE_SOCIAL');
    const [isOnline, setIsOnline] = useState(true);

    const today = new Date();
    const isWinter = (today.getMonth() === 11) ||
        (today.getMonth() === 0 && today.getDate() <= 5);

    if (sessionStorage.getItem('setImportComplete') === 'true') {
        const newMessage = {
            sender: 'assistant',
            content: t('mapping:successPrompt') + "(" + i18n.language + ")",
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        };

        setIsAwaitingResponse(true);
        setTimeout(() => {
            setIsAwaitingResponse(false);
            addMessage(newMessage);
        }, 1000);

        sessionStorage.removeItem('setImportComplete');
    }

    const introToggle = () => {
        if (isCooldownIntro) return
        setIsCooldownIntro(true);
        setIntroVisible((prevValue: boolean) => {
            const newValue = !prevValue;
            localStorage.setItem('introVisible', JSON.stringify(newValue));
            return newValue;
        });
        setfirstOpenIntro(true);
        setTimeout(() => {
            setIsCooldownIntro(false);
        }, 300);
    }

    const openChat = () => {
        if (isCooldown) return
        toggleChat();
        setfirstOpen(true);
        setIsCooldown(true);
        setIsWaveCooldown(true);
        setTimeout(() => {
            setIsCooldown(false);
        }, 400);
        setTimeout(() => {
            setIsWaveCooldown(false);
        }, 500);
    }

    useEffect(() => {
        checkHealthStatus();
    },[])

    useEffect(() => {
        localStorage.setItem('introVisible', JSON.stringify(introVisible));
    }, [introVisible]);

    useEffect(() => {
        const savedMessages = sessionStorage.getItem('chatMessages');
        if (savedMessages) {
            setMessages(JSON.parse(savedMessages));
        } else {
            const initialMessage = {
                sender: 'assistant',
                content: `start message`,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
            setMessages([initialMessage]);
        }
    }, [location.pathname, setMessages]);

    useEffect(() => {
        if (messages.length > 0) {
            sessionStorage.setItem('chatMessages', JSON.stringify(messages));
        }
    }, [messages]);

    const checkHealthStatus = async () => {
        try {
          await checkHealth({}).unwrap();
          setIsOnline(true)
        } catch (error) {
          const fetchError = error as FetchBaseQueryError;
          if (fetchError.status !== 200) setIsOnline(false)
        };
    }

    const getPathName = (pathCode: string): string => {
        const sanitizedPath = pathCode.endsWith("/") && pathCode.length > 1 ? pathCode.slice(0, -1) : pathCode;
        const normalizedPath = sanitizedPath.replace(/\/[a-f0-9\-]{36}$/, '/*');

        const pageMap: { [key: string]: string } = {
            [`/${i18n.language}/dashboard`]: 'dashboard',
            [`/${i18n.language}/dashboard/success`]: 'digipas activated',
            [`/${i18n.language}/dashboard/cards/list`]: 'card overview',
            [`/${i18n.language}/dashboard/cards/edit/*`]: 'card edit',
            [`/${i18n.language}/dashboard/cards/create`]: 'card create',
            [`/${i18n.language}/dashboard/cards/import`]: 'import cards',
            [`/${i18n.language}/dashboard/organisation/appbuilder`]: 'appbuilder',
            [`/${i18n.language}/dashboard/organisation/theme`]: 'theme',
            [`/${i18n.language}/dashboard/organisation/socials`]: 'socials',
            [`/${i18n.language}/dashboard/news/list`]: 'news',
            [`/${i18n.language}/dashboard/news/create`]: 'create news',
            [`/${i18n.language}/dashboard/news/edit/*`]: 'edit news',
            [`/${i18n.language}/dashboard/notify`]: 'notification',
            [`/${i18n.language}/dashboard/notify/history`]: 'notification history',
            [`/${i18n.language}/dashboard/users/list`]: 'users overview',
            [`/${i18n.language}/dashboard/users/edit/*`]: 'user edit',
            [`/${i18n.language}/dashboard/users/create`]: 'user create',
            [`/${i18n.language}/dashboard/sponsor/list`]: 'sponsor overview',
            [`/${i18n.language}/dashboard/sponsor/create`]: 'sponsor create',
            [`/${i18n.language}/dashboard/sponsor/edit/*`]: 'sponsor edit',
            [`/${i18n.language}/dashboard/admin/organisation/list`]: 'adminpage organisations overview',
            [`/${i18n.language}/dashboard/admin/organisation/edit/*`]: 'adminpage organisation edit',
            [`/${i18n.language}/dashboard/admin/organisation/create`]: 'adminpage organisation create',
            [`/${i18n.language}/dashboard/admin/suggestion/list`]: 'adminpage suggestion overview',
            [`/${i18n.language}/dashboard/admin/suggestion/edit/*`]: 'adminpage suggestion edit',
            [`/${i18n.language}/dashboard/admin/suggestion/create`]: 'adminpage suggestion create',
            [`/${i18n.language}/dashboard/admin/notes/list`]: 'adminpage release notes overview',
            [`/${i18n.language}/dashboard/admin/notes/edit/*`]: 'adminpage release note edit',
            [`/${i18n.language}/dashboard/admin/notes/create`]: 'adminpage release note create',
        };

        return pageMap[normalizedPath] || 'unknown';
    };


    const handleSendMessage = (msg?: string) => {
        const content = msg || message.trim();
        if (content) {
            const newMessage = {
                sender: 'human',
                content: content + "(" + i18n.language + ")",
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
            addMessage(newMessage);
            setMessage('');
            setIsAwaitingResponse(true);

            const buildConversationHistory = () => {
                return messages.map((msg) => [msg.sender, msg.content]);
            };

            const sendMessage = async () => {
                if (content.toLowerCase() === '/c' || content.toLowerCase() === 'clear chat') {
                    setMessages([
                        {
                            sender: 'assistant',
                            content: `start message`,
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        },
                    ]);
                    sessionStorage.removeItem('chatMessages');
                    setIsAwaitingResponse(false);
                    return;
                }

                try {
                    const human_message = content + ' Location: ' + getPathName(location.pathname) + ' page';
                    const conversation_history = buildConversationHistory();
                    const data = await chatMessage({ human_message, conversation_history }).unwrap();
                    if (data) {
                        const GPTmessage = {
                            sender: 'assistant',
                            content: data.response,
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        };
                        addMessage(GPTmessage);
                        setIsAwaitingResponse(false);
                    }
                } catch (error) {
                    console.error('Error sending message:', error);
                    checkHealthStatus();
                    setIsAwaitingResponse(false);
                }
            };
            sendMessage();
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        const translatedSuggestion = t(suggestion);
        handleSendMessage(translatedSuggestion);
        setSuggestions(prevSuggestions => {
            const updatedSuggestions = prevSuggestions.filter(item => item !== suggestion);
            sessionStorage.setItem('chatSuggestions', JSON.stringify(updatedSuggestions));
            return updatedSuggestions;
        });
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const validExtensions = ['csv', 'xls', 'xlsx'];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !validExtensions.includes(fileExtension)) {
                return;
            }

            const updatedMessages = messages.map(msg => {
                const languageRegex = /\((\w{2})\)$/;
                const messageContent = msg.content.replace(languageRegex, '').trim();
                const previousLanguage = msg.content.match(languageRegex)?.[1];
                if (messageContent === "gotobuttons : true") {
                    return { ...msg, content: "relocate : true(" + previousLanguage + ")" };
                }
                return msg;
            });
            setMessages(updatedMessages);

            setIsAwaitingResponse(true);
            const newMessage = {
                sender: 'human',
                content: `${t("patrick:uploadedFile")} ${file.name}`,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };

            addMessage(newMessage);
            try {
                const data = await uploadFile({ org_id, file }).unwrap()
                if (data) {
                    setTimeout(() => {
                        const followUpMessage = {
                            sender: 'assistant',
                            content: "gotobuttons : true(" + i18n.language + ")",
                            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                        };
                        addMessage(followUpMessage);
                        setIsAwaitingResponse(false);
                    }, Math.floor(Math.random() * (2000 - 1100 + 1)) + 1100);
                }
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        const container = document.getElementById('chatContainer');
        if (container) {
            const distanceFromBottom = container.scrollHeight - container.scrollTop - container.clientHeight;

            setAtBottom(distanceFromBottom <= BOTTOM_THRESHOLD);

            setCanScroll(container.scrollHeight > container.clientHeight);
        }
    };

    useEffect(() => {
        const container = document.getElementById('chatContainer');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            setCanScroll(container.scrollHeight > container.clientHeight);
            scrollToBottom();

            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);


    useEffect(() => {
        if (isChatOpen) {
            scrollToBottom();
        }
    }, [isChatOpen, messages]);

    const handleSuggestionSwitch = () => {
        setShowSuggestions((prevValue: boolean) => {
            const newValue = !prevValue;
            localStorage.setItem('showSuggestions', JSON.stringify(newValue));
            setTimeout(() => {
                scrollToBottom();
            }, 150);
            return newValue;
        });
    };

    const handleButtonClick = () => {
        const updatedMessages = messages.map(msg => {
            const languageRegex = /\((\w{2})\)$/;
            const messageContent = msg.content.replace(languageRegex, '').trim();
            const previousLanguage = msg.content.match(languageRegex)?.[1];
            if (messageContent === "gotobuttons : true") {
                return { ...msg, content: "relocate : true(" + previousLanguage + ")" };
            }
            return msg;
        });

        setMessages(updatedMessages);

        setTimeout(async () => {
            const validatefileStatus = await validateTemplateFile()

            if (validatefileStatus) {
                navigate("/" + i18n.language + "/dashboard/cards/import/map#errors");
            } else {
                navigate("/" + i18n.language + "/dashboard/cards/import/map");
            }
        }, 5);
    };

    const validateTemplateFile = async () => {
        try {
            setIsAwaitingResponse(true)
            const data = await verifyTemplate(org_id).unwrap();
            if (data) {
                return data.response;
            } else {
                return false
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsAwaitingResponse(false);
        }
    }

    const findValidPreviousMessage = (messages: Messages, index: number): Message | null => {
        let currentIndex = index - 1;

        while (currentIndex >= 0) {
            const currentMessage = messages[currentIndex];
            if (!(currentMessage.content.includes('busy : true') && /\((de|nl|fr|es|en)\)/i.test(currentMessage.content))) {
                return currentMessage;
            }
            currentIndex--;
        }

        return null;
    };

    const renderMessages = () => {
        return messages.map((msg, index) => {
            const prevMessage = findValidPreviousMessage(messages, index);
            const isNewSender = !prevMessage || prevMessage.sender !== msg.sender;
            const nextMessage = messages[index + 1];
            const isLastInSequence = !nextMessage || nextMessage.sender !== msg.sender;

            const languageRegex = /\((\w{2})\)(?=[^\w]*$)/;
            const match = msg.content.match(languageRegex);
            const messageLanguage = match ? match[1].toLowerCase() : i18n.language.toLowerCase();
            const messageContent = msg.sender === 'assistant'
                ? msg.content.replace(languageRegex, '').replace(/\s+upload\s*:\s*true/g, '').replace(/\s+Upload\s*:\s*true/g, '').replace(/\s+upload\s*:\s*false/g, '').trim()
                : msg.content.replace(languageRegex, '').trim();

            const regex = /(\*\*[^*]+\*\*|\*[^*]+\*|_[^_]+_|`[^`]+`|~~[^~]+~~|``[^`]+``|```[^`]+```)/g;
            let idx = 0;

            const parseToken = (token: string) => {
                if (token.startsWith('**') && token.endsWith('**')) {
                    return <b key={idx++}>{parseToken(token.slice(2, -2))}</b>;
                } else if ((token.startsWith('*') && token.endsWith('*')) || (token.startsWith('_') && token.endsWith('_'))) {
                    return <i key={idx++}>{parseToken(token.slice(1, -1))}</i>;
                } else if (token.startsWith('```') && token.endsWith('```')) {
                    return <code className='large-code' key={idx++}>{token.slice(4, -3)}</code>;
                } else if (token.startsWith('``') && token.endsWith('``')) {
                    return <code className='large-code' key={idx++}>{token.slice(2, -2)}</code>;
                } else if (token.startsWith('`') && token.endsWith('`')) {
                    return <code className='normal-code' key={idx++}>{token.slice(1, -1)}</code>;
                } else if (token.startsWith('~~') && token.endsWith('~~')) {
                    return <del key={idx++}>{parseToken(token.slice(2, -2))}</del>;
                } else {
                    return token;
                }
            };

            const tokens = messageContent.split(regex);

            const formattedMessageContent = tokens.map((token) => {
                return parseToken(token);
            });

            return (
                <>
                    <div key={`${msg.time}-${index}`} className={msg.sender === 'assistant' && (messageContent === 'busy : true') && !isLastInSequence ? '' : 'mb-1.5'}>
                        {/* icon for the upload button if needed */}
                        {msg.sender === 'assistant' && messageContent === 'upload : true' ? (
                            <>
                                {isNewSender ? (
                                    <div className='-mb-[50px] relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                        <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                        {isWinter && (
                                            <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                        )}
                                    </div>
                                ) : (
                                    <div className='-mt-1.5'></div>
                                )}
                            </>

                            // start mapping prompt + button
                        ) : msg.sender === 'assistant' && (messageContent === 'gotobuttons : true' || messageContent === 'relocate : true') ? (
                            <>
                                <div className={`flex justify-start ${!isNewSender ? 'pl-10' : ''}`}>
                                    {isNewSender && (
                                        <div className='relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                            <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                            {isWinter && (
                                                <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                            )}
                                        </div>
                                    )}

                                    {/* look if user is allowed to import */}
                                    {!isSocialUser ? (
                                        <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}>
                                            {t("patrick:importPrompt", { lng: messageLanguage })}
                                            <button className="bg-[#3273f6] text-white rounded-[10px] px-3 py-2 text-xs w-[100%] my-1 disabled:bg-[#7e7e7e]" onClick={() => handleButtonClick()} disabled={messageContent === 'relocate : true'}>
                                                {t('patrick:mapping', { lng: messageLanguage })}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}>
                                            {t("patrick:notAllowed", { lng: messageLanguage })}
                                        </div>
                                    )}
                                </div>
                            </>

                            // download prompt
                        ) : msg.sender === 'assistant' && (messageContent === 'download : true') ? (
                            <>
                                <div className={`flex justify-start ${!isNewSender ? 'pl-10' : ''}`}>
                                    {isNewSender && (
                                        <div className='relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                            <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                            {isWinter && (
                                                <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                            )}
                                        </div>
                                    )}
                                    <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}>
                                        {t("patrick:downloadPrompt", { lng: messageLanguage })} <a className='my-2 text-[#3273f6] hover:underline' href={Constants.DOWNLOAD_URL_CARDS_TEMPLATE} download>{t("patrick:downloadPromptSecondary", { lng: messageLanguage })}</a>.
                                    </div>
                                </div>
                            </>

                            // loader message from text
                        ) : msg.sender === 'assistant' && (messageContent === 'busy : true') ? (
                            isLastInSequence ? (
                                <div key={`${msg.time}-${index}-load`} className="mt-1.5 mb-1.5">
                                    <div className={`flex justify-start mt-2.5`} >
                                        <div className='relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                            <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                            {isWinter && (
                                                <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                            )}
                                        </div>
                                        <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] break-words h-[26px] whitespace-pre-wrap ${[".csv", ".xls", ".xlsx"].some(ext => msg.content.toLocaleLowerCase().includes(ext)) ? `busy` : 'loader '}`} >
                                            <p className='opacity-0'>{[".csv", ".xls", ".xlsx"].some(ext => msg.content.toLocaleLowerCase().includes(ext)) ? t("patrick:busy") : t("patrick:typingLoader")}...</p>
                                        </div>
                                    </div>
                                </div>
                            ) : null

                            // normal message
                        ) : (
                            <div className={`flex ${msg.sender === 'assistant' ? 'justify-start' : 'justify-end'} ${msg.sender === 'assistant' && !isNewSender ? 'pl-10' : ''} ${msg.sender === 'human' && !isNewSender ? 'pr-11' : ''}`}>
                                {isNewSender && msg.sender === 'assistant' && (
                                    <div className='relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                        <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                        {isWinter && (
                                            <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                        )}
                                    </div>
                                )}
                                <div className={`inline-block p-4 ${msg.sender === 'assistant' ? 'border' : 'bg-[#F5F8FB]'} border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}>
                                    {msg.sender === 'assistant'
                                        ? (msg.sender === 'assistant' && messageContent === 'start message'
                                            ? isOnline
                                                ? t("patrick:startPrompt", { lng: i18n.language })
                                                : <>{t("patrick:offlinePrompt", { lng: i18n.language })} <a className='text-[#3273f6] hover:underline' href="mailto: support@digipas.app">support@digipas.app</a>.</>
                                            : formattedMessageContent)
                                        : messageContent}
                                    {msg.sender === 'assistant' && msg.content.toLocaleLowerCase().includes("upload : true") && "\n\n" + t("patrick:importingSuggestionPrompt", { lng: messageLanguage })}
                                </div>

                                {msg.sender === 'human' && isNewSender && (
                                    <div className="w-9 h-9 ml-1.5 mt-2 bg-[#3273f6] text-white flex items-center justify-center text-xs rounded-full">
                                        {t("patrick:you")}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* upload button */}
                        {msg.sender === 'assistant' && (msg.content.toLocaleLowerCase().includes("upload : true") || msg.content.includes("upload : false")) && (
                            <div className={`flex justify-start mt-1.5`}>
                                <div className='pl-10'></div>

                                {/* look if user is allowed to import */}
                                {!isSocialUser ? (
                                    <div className="max-w-[400px] p-[6px] pl-[16px] border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] flex flex-row items-center gap-[5px]">
                                        {t("patrick:fileUploadPrompt", { lng: messageLanguage })}
                                        <label htmlFor={isOnline ? 'csvFileInput' : undefined} className={isOnline ? 'cursor-pointer' : 'cursor-not-allowed'}>
                                            <div className={`${isOnline && 'shake'} max-w-[400px] bg-[#F5F8FB] rounded-[25px] text-[#5D6C78] flex flex-row items-center gap-[5px] p-[10px]`}>
                                                <img src={paperclip} alt="File upload" className="shake-item w-2.5 h-4" />
                                                {t("patrick:upload", { lng: messageLanguage })}
                                            </div>
                                        </label>
                                    </div>
                                ) : (
                                    <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] text-[14px] break-words max-w-[190px] md:max-w-[270px] whitespace-pre-wrap`}>
                                        {t("patrick:notAllowed", { lng: messageLanguage })}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Time indicator on last message */}
                        {isLastInSequence && (
                            <div className={`text-[#B6BFC7] flex ${msg.sender === 'assistant' ? 'justify-start pl-16' : 'justify-end pr-16'} text-xs mb-2.5`}>
                                {msg.time}
                            </div>
                        )}
                    </div>

                    {/* Loader if we are waiting for the AI */}
                    {isLastInSequence && !nextMessage && isAwaitingResponse && (
                        <div key={`${msg.time}-${index}-load`} className="mb-1.5">
                            <div className={`flex justify-start mt-2.5`} >
                                <div className='relative rounded-full bg-[#e3e2dc] mr-1.5 mt-[9px] w-[35px] h-[35px] overflow-hidden'>
                                    <img src={bot} alt="Patrick assistance" className="absolute top-0 left-0 w-[70px] mt-[4px]" />
                                    {isWinter && (
                                        <img src={santa} alt="Hat" className={`absolute w-4 mt-[1px] ml-[9.5px] rotate-[10deg] transition-transform duration-300`} />
                                    )}
                                </div>
                                <div className={`inline-block p-4 border border-[#D9E3F1] rounded-[25px] text-[#5D6C78] break-words h-[26px] whitespace-pre-wrap ${[".csv", ".xls", ".xlsx"].some(ext => msg.content.toLocaleLowerCase().includes(ext)) ? `busy` : 'loader '}`} >
                                    <p className='opacity-0'>{[".csv", ".xls", ".xlsx"].some(ext => msg.content.toLocaleLowerCase().includes(ext)) ? t("patrick:busy") : t("patrick:typingLoader")}...</p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        });
    };

    return (
        <>
            <div className="fixed bottom-4 right-4 z-[100] pointer-events-none">
                <style>
                    {`
                    .large-code { background-color: #F5F8FB; padding: 2px 4px; border-radius: 4px; display: block; }
                    .normal-code { background-color: #F5F8FB; padding: 2px 4px; border-radius: 4px; }

                    .loader { position: relative; display: inline-block; overflow: hidden; background-color: #E7E7E7;}
                    .loader::before { content: '${t('patrick:typingLoader')}'; position: absolute; transform: translateY(-10px); animation: typing 3s infinite; z-index: 1; }
                    @keyframes typing { 25% { content: '${t('patrick:typingLoader')}'; } 50% { content: '${t('patrick:typingLoader')}.';  } 75% { content: '${t('patrick:typingLoader')}..';  } 100% { content: '${t('patrick:typingLoader')}...';  } }
                    .loader::after { content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.05); animation: loading 3s infinite; z-index: 0; }
                    .busy { position: relative; display: inline-block; overflow: hidden; background-color: #E7E7E7;}
                    .busy::before { content: '${t('patrick:busy')}'; position: absolute; transform: translateY(-10px); animation: busy 3s infinite; z-index: 1; }
                    @keyframes busy { 25% { content: '${t('patrick:busy')}'; } 50% { content: '${t('patrick:busy')}.';  } 75% { content: '${t('patrick:busy')}..';  } 100% { content: '${t('patrick:busy')}...';  } }
                    .busy::after { content: ''; position: absolute; left: 0; top: 0; height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.05); animation: loading 3s infinite; z-index: 0; }
                    @keyframes loading { 0% { left: -100%; width: 100%; } 50% { left: 0; width: 100%; } 100% { left: 100%; width: 0; } }
                    .rotate-90 { animation: rotate-90 0.5s ease-in-out; }
                    @keyframes rotate-90 { 0% { transform: rotate(0deg); } 100% { transform: rotate(90deg); } }
                    .rotate-0 { animation: rotate-0 0.5s ease-in-out; }
                    @keyframes rotate-0 { 0% { transform: rotate(90deg); } 100% { transform: rotate(0deg); } }
                    .move-up { animation: move-up 0.5s ease-in-out; }
                    @keyframes move-up { 0% { transform: translateY(20px); } 100% { transform: translateY(0); } }
                    .move-down { transform: translateY(20px); animation: move-down 0.5s ease-in-out; }
                    @keyframes move-down { 0% { transform: translateY(0); } 100% { transform: translateY(20px); } }
                    .move-up-xmas { animation: move-up-xmas 0.5s ease-in-out; }
                    @keyframes move-up-xmas { 0% { transform: translateY(20px); rotate: 0deg; } 100% { transform: translateY(0); rotate: 10deg; } }
                    .move-down-xmas { transform: rotate(0deg); transform: translateY(20px); animation: move-down-xmas 0.5s ease-in-out; }
                    @keyframes move-down-xmas { 0% { transform: translateY(0); rotate: 10deg; } 100% { transform: translateY(20px); rotate: 0deg; } }
                    .move-to-left { animation: move-to-left 0.5s ease-out; }
                    @keyframes move-to-left { 0% { transform: translateX(500px) scale(0.75); } 100% { transform: translateX(0)scale(1); } }
                    .move-to-right { animation: move-to-right 0.5s ease-in; }
                    @keyframes move-to-right { 0% { transform: translateX(0) scale(1); } 100% { transform: translateX(500px) scale(0.75); } }
                    .move-to-left-grow { animation: move-to-left-grow 1s ease-in-out; }
                    @keyframes move-to-left-grow { 0% { transform: translateX(500px) scale(0); } 100% { transform: translateX(0) scale(1); } }
                    .move-to-right-shrink { animation: move-to-right-shrink 1s ease-in-out; }
                    @keyframes move-to-right-shrink { 0% { transform: translateX(0) scale(1); } 100% { transform: translateX(500px) scale(0); } }
                    .grow { animation: grow 1s ease-in-out; }
                    @keyframes grow { 0% { transform: scale(0); } 50% { transform: scale(0); } 100% { transform: scale(1); } }
                    .shrink { animation: shrink 1s ease-in-out; transform: scale(0); }
                    @keyframes shrink { 0% { transform: scale(1); } 50% { transform: scale(0); } 100% { transform: scale(0); } }
                    .wave { animation: wave 1.5s ease-in-out; transform-origin: bottom right; }
                    @keyframes wave { 0% { transform: rotate(0deg); } 25% { transform: rotate(10deg); } 50% { transform: rotate(-10deg); } 75% { transform: rotate(10deg); } 100% { transform: rotate(0deg); } }
                    .rotate-full-in { animation: rotate-full-in 1s ease-in-out; }
                    @keyframes rotate-full-in { 0% { transform: rotate(0deg); } 50% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
                    .rotate-full-out { animation: rotate-full-out 1s ease-in-out; }
                    @keyframes rotate-full-out { 0% { transform: rotate(360deg); } 50% { transform: rotate(0deg); } 100% { transform: rotate(0deg); } }
                    .send-transformer { transition: transform 0.8s ease-in-out; }
                    .send-hover:hover .send-hover-item { animation: send-hover 1.5s ease-in-out infinite; }
                    @keyframes send-hover { 0% { transform: translate(-0px, 0px); } 60% { transform: translate(-3px, 3px); } 75% { transform: translate(1px, -1px); } 85% { transform: translate(-0px, 0px); } 100% { transform: translate(-0px, 0px); } }
                    .send { animation: send 1s ease-in-out; transform: translate(-30px, 30px); }
                    @keyframes send { 0% { transform: translate(2px, -2px); } 35% { transform: translate(-10px, 10px) ; } 100% { transform: translate(20px, -20px) ; } }
                    .shake:hover .shake-item { animation: shake 0.5s ease-in-out;}
                    @keyframes shake { 0% { transform: rotate(0deg); } 25% { transform: rotate(15deg); } 50% { transform: rotate(-10deg); } 75% { transform: rotate(10deg); } 100% { transform: rotate(0deg); } }
                    .move-arrow-right { animation: move-arrow-right 0.5s linear; }
                    @keyframes move-arrow-right { 0% { transform: translateX(-50px) rotate(-90deg); } 75% { transform: translateX(0) rotate(-90deg); } 100% { transform: translateX(0) rotate(0deg); } }
                    .move-arrow-left { animation: move-arrow-left 0.5s linear; transform: translateX(-50px) rotate(90deg); }
                    @keyframes move-arrow-left { 0% { transform: translateX(0) rotate(0deg); } 25% { transform: translateX(0) rotate(90deg); } 100% { transform: translateX(-50px) rotate(90deg); } }
                    `}
                </style>
                <div className={`pointer-events-auto flex flex-col ${!firstOpen && !isChatOpen ? 'translate-x-[500px]' : (!isChatOpen ? 'move-to-right translate-x-[500px]' : 'move-to-left')}`}>
                    <div className="relative w-[310px] md:w-[410px] h-[70vh] max-h-[600px] bg-white border border-[#D9E3F1] shadow-lg rounded-[31px] mb-2.5 flex flex-col">
                        <div className="rounded-t-[31px] bg-[#F5F8FB] border-b border-[#D9E3F1] h-[65px] flex flex-col md:flex-row justify-between p-4">
                            <div className="flex flex-row items-center">
                                <div className="ml-1 font-bold text-xl">{t("patrick:name")}</div>
                                <img src={wave} alt="Wave" className={`w-8 h-7 md:w-9 md:h-9 mr-2.5 ${!isWaveCooldown ? 'wave' : ''} `} />
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                {isOnline ?
                                    <>
                                        <img src={ellipse} alt="Status" className="w-2 h-2" />
                                        <div className="text-xs text-[#5D6C78]">{t("patrick:online")}</div>
                                    </>
                                    :
                                    <>
                                        <img src={ellipseRed} alt="Status" className="w-2 h-2" />
                                        <div className="text-xs text-[#5D6C78]">{t("patrick:offline")}</div>
                                    </>
                                }

                            </div>
                        </div>

                        <div id="chatContainer" className="flex flex-col flex-grow overflow-y-scroll p-2.5 z-10 no-scrollbar">
                            {renderMessages()}
                            <div ref={messagesEndRef} />

                        </div>

                        <div className='absolute bottom-[10px] left-[11px] h-[56.7px] rounded-l-full flex items-center overflow-hidden px-[14px] pointer-events-none'>
                            <FontAwesomeIcon
                                className={`p-1 bg-map-blue rounded-full cursor-pointer h-6 w-6 text-white z-10 pointer-events-auto ${canScroll ? (atBottom ? 'move-arrow-left' : 'move-arrow-right') : 'hidden'}`}
                                icon={faArrowDown}
                                onClick={scrollToBottom}
                            />
                        </div>

                        <div className={`border-t-[1px] border-[#D9E3F1] bg-[#FBFCFD] rounded-b-[31px] ${!isOnline && 'cursor-not-allowed'}`}>
                            <FontAwesomeIcon className={`z-20 absolute mt-[-11px] right-[5px] w-[10px] h-[10px] p-[5px] border-[1px] border-[#D9E3F1] bg-[#FBFCFD] cursor-pointer rounded-full transition-transform duration-200 ${showSuggestions ? '' : 'transform rotate-180'}`} icon={faChevronDown} onClick={handleSuggestionSwitch} />
                            <motion.div animate={(!showSuggestions) ? { height: '0rem' } : { height: 'auto' }} className={`overflow-hidden ${!isOnline && 'pointer-events-none'}`}>
                                <div className="mt-2 ml-2.5 mr-2.5">
                                    <ChatboxSuggestions isAwaitingResponse={isAwaitingResponse} onSuggestionClick={handleSuggestionClick} tabIndex={isChatOpen ? 0 : -1} />
                                </div>
                            </motion.div>

                            <div className={`${!isOnline && 'pointer-events-none'} bg-[#FFFFFF] rounded-full border border-[#D9E3F1] flex flex-row items-center p-1.5 m-2.5 max-h-[100px]`}>
                                <img src={chat} alt="Chat" className="m-3.5 md:flex hidden" />

                                <input tabIndex={isChatOpen ? 0 : -1} type="text" className="w-[65%] md:pl-0 pl-3 h-6 text-base border-none outline-none resize-none" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter" && !e.shiftKey && !isAwaitingResponse) { e.preventDefault(); handleSendMessage(); } }} placeholder={t("patrick:typeing") || ""} />

                                <input type="file" accept=".CSV, .XLS, .XLSX" className="hidden" id="csvFileInput" onChange={handleFileUpload} disabled={isAwaitingResponse} />
                                <label  htmlFor="csvFileInput" className={`${isAwaitingResponse ? 'cursor-default' : 'cursor-pointer'}`}>
                                    <div className={`${isAwaitingResponse ? '' : 'shake'} w-10 h-10 rounded-full flex items-center justify-center m-0.5 ${isAwaitingResponse ? 'bg-gray-400' : 'bg-[#F5F8FB]'}`}>
                                        <img src={paperclip} alt="File upload" className="shake-item w-2.5 h-4" />
                                    </div>
                                </label>

                                <button tabIndex={isChatOpen ? 0 : -1} onClick={() => handleSendMessage()} disabled={isAwaitingResponse} className={`${isAwaitingResponse ? '' : 'send-hover'} disabled:bg-[#5D6C78] disabled:cursor-default ${isInputNotEmpty ? 'bg-[#3273F6] cursor-pointer' : 'bg-[#285FCF] cursor-default'} transition-colors duration-300 w-10 h-10 rounded-full flex items-center justify-center m-0.5 overflow-hidden`} >
                                    <img src={send} alt="Send" className={`w-4 h-4.5 send-transformer ${isInputNotEmpty ? 'send-hover-item' : ''} ${isAwaitingResponse ? 'send' : ''}`} />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="flex flex-row">

                    {/* open introduction (text) */}
                    <div className='w-[270px] md:w-[370px] pb-5 -mb-5 overflow-hidden'>
                        <div className={`pointer-events-auto flex flex-row items-center relative justify-between w-[222px] md:w-[322px] h-[83px] bg-[#252525] rounded-[31px] mr-1.5 z-[1] transition-opacity duration-300 ${!firstOpenIntro ? (introVisible ? 'move-to-left-grow' : 'translate-x-[500px]') : (introVisible ? 'move-to-left-grow' : 'move-to-right-shrink translate-x-[500px]')}`}>
                            <div className="flex justify-center text-center flex-col flex-grow">
                                {getPathName(location.pathname) === "unknown" ? (
                                    <div className="text-[20px] md:text-lg text-white font-bold">{t("patrick:unknownLocationTitle")}</div> //if the popup says location unknown update the getPathName, if you're adding a new page mabye also add some suggestions :)
                                ) : (
                                    <>
                                        <div className="text-[20px] md:text-lg text-white font-bold">{t("patrick:greeting")}</div>
                                        <div className="text-[0px] md:text-[15px] text-white font-light">{t("patrick:help")}</div>
                                    </>
                                )}
                            </div>
                            <div tabIndex={introVisible ? 0 : -1} className={`absolute right-[-5px] bottom-[-5px] w-7 h-7 shadow-lg bg-[#252525] rounded-[40%] flex justify-center ${introVisible ? 'cursor-pointer' : 'cursor-default'} items-center`} onKeyDown={(e) => { if (e.key === "Enter") { introToggle(); } }} onClick={() => introToggle()} aria-label="Close introduction">
                                <img src={close} alt="Close introduction" className="w-3 h-3" />
                            </div>
                        </div>
                    </div>

                    {/* closed introduction (button) */}
                    <div className="flex flex-row items-center justify-between w-[0px] md:w-[0px] h-[83px] mr-1.5">
                        <div onKeyDown={(e) => { if (e.key === "Enter") { introToggle(); } }} tabIndex={introVisible ? -1 : 0} className={`pointer-events-auto absolute right-[90px] ${getPathName(location.pathname) === "unknown" ? 'border-dashed border-2 border-red-500' : ''} bg-[#F5F8FB] shadow-lg w-[40px] h-[40px] rounded-[50%] flex justify-center items-center ${!firstOpenIntro ? (!introVisible ? 'grow' : 'opacity-0 pointer-events-none') : (!introVisible ? 'grow cursor-pointer' : 'shrink cursor-default')}`} onClick={() => introToggle()} aria-label="Open introduction" >
                            <IconSVG className={`h-3 ${!firstOpenIntro ? (!introVisible ? 'rotate-full-in' : '') : (!introVisible ? 'rotate-full-in' : 'rotate-full-out')}`} />
                        </div>
                    </div>

                    {/* open chat */}
                    <div tabIndex={0} className={`pointer-events-auto w-[83px] h-[83px] bg-[#e3e2dc] fixed bottom-4 right-4 shadow-lg rounded-full flex justify-center items-center ${isCooldown ? '' : 'cursor-pointer'} overflow-hidden z-[2]`} onKeyDown={(e) => { if (e.key === "Enter") { isCooldown ? null : openChat(); } }} onClick={() => { isCooldown ? null : openChat(); }} >
                        <img src={bot} alt="Open Patrick assistance" className={`absolute top-[8px] ${isChatOpen ? 'move-down' : 'move-up'}`} />
                        {isWinter && (
                            <img src={santa} alt="Hat" className={`absolute w-10 -mt-[45px] -ml-[2px] rotate-[10deg] transition-transform duration-300 ${isChatOpen ? 'move-down-xmas' : 'move-up-xmas'}`} /> // this is where Patrick AI peaked
                        )}
                    </div>

                    {/* close chat */}
                    <div className={`pointer-events-auto w-[84px] h-[84px] bg-[#EA325C] fixed bottom-[15.5px] right-[15.5px] shadow-lg rounded-full flex justify-center items-center ${isCooldown ? '' : 'cursor-pointer'} overflow-hidden z-[2] transition-opacity duration-300 ${isChatOpen ? 'opacity-100' : 'opacity-0'}`} onClick={() => { isCooldown ? null : openChat(); }} >
                        <img src={close} alt="Close Patrick assistance" className={`w-5 h-5 ${!firstOpen ? '' : (isChatOpen ? 'rotate-90' : 'rotate-0')}`} />
                    </div>
                </div>

            </div>
            {getPathName(location.pathname) === "unknown" ? (
                <div className={`fixed bottom-[75px] right-[15px] w-[25px] h-[25px] bg-[#FF0000] rounded-full ${isCooldown ? '' : 'cursor-pointer'} z-[101] shadow-lg flex justify-center text-white animate-ping`} onClick={() => { isCooldown ? null : openChat(); }} >!</div> //update the getLanguage or getPathName read comments above for more info
            ) : null}
        </>
    );
};

export default ChatBox;
