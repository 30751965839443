import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

export interface WizardLineProps {
    items: Array<string>,
    current: number
}

const WizardLine = ({ items, current }: WizardLineProps) => {
    return (
        <div className='hidden md:flex'>
            {items.map((item, index) => (
                <div key={ index } className={`transition-colors font-title relative px-4 py-2 mx-1 ${ index < current && 'text-accent-light' } `} style={{ width: `${100 / items.length}%` }}>
                    { item }
                    <AnimatePresence>
                    { index <= current && (
                        <>
                            <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} initial={{ width: '0%' }} animate={{ width: '100%' }} exit={{ width: '0%' }} className='absolute left-0 bottom-0 w-full h-1 bg-accent-light rounded-full'/>
                            { index == current && <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} layoutId='current_step' className='absolute z-[2] -right-2 -bottom-1.5 w-4 h-4 bg-accent rounded-full'/> }
                        </>
                    ) }
                    </AnimatePresence>
                </div>
            ))}
        </div>
    )
}

export default WizardLine