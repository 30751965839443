import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Title from '../Title';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheckCircle, faXmarkCircle, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

export interface StatusPopupsProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text?: string;
    subtitle?: string;
    type: 'error' | 'success' | 'warning';
    alignLeft?: boolean;
    setText?: (title: string) => void;
    setSubtitle?: (subtitle: string) => void;
}

const StatusPopups = ({ text, subtitle, type, alignLeft, setText, setSubtitle }: StatusPopupsProps) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(!!text);
    const [progress, setProgress] = useState(0);
    const [animationFrame, setAnimationFrame] = useState<number | null>(null);

    useEffect(() => {
        if (text) {
            setIsVisible(true);
            setProgress(0);
        } else {
            setIsVisible(false);
            setProgress(0);
            if (animationFrame !== null) {
                cancelAnimationFrame(animationFrame);
                setAnimationFrame(null);
            }
        }
    }, [text]);

    useEffect(() => {
        if (!isVisible) return;

        let duration = type === 'success' ? 5000 : 7500;
        let start = Date.now();

        const updateProgress = () => {
            const elapsed = Date.now() - start;
            const percentage = Math.min((elapsed / duration) * 100, 100);
            setProgress(percentage);

            if (percentage < 100) {
                const frame = requestAnimationFrame(updateProgress);
                setAnimationFrame(frame);
            } else {
                closePopup();
            }
        };

        const frame = requestAnimationFrame(updateProgress);
        setAnimationFrame(frame);

        return () => {
            if (animationFrame !== null) {
                cancelAnimationFrame(animationFrame);
                setAnimationFrame(null);
            }
        };
    }, [isVisible]);

    const closePopup = () => {
        setIsVisible(false);
        setProgress(0);
        if (animationFrame !== null) {
            cancelAnimationFrame(animationFrame);
            setAnimationFrame(null);
        }
        setTimeout(() => {
            if (setText) setText('');
            if (setSubtitle) setSubtitle('');
        }, 600);
    };

    if (!text) return null;

    let bgColor = '';
    let title = '';
    let message = '';

    switch (type) {
        case 'error':
            bgColor = 'bg-gradient-to-r from-red-400 via-red-500 to-red-600';
            title = text;
            message = subtitle ? subtitle : t('error');
            break;
        case 'warning':
            bgColor = 'bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600';
            title = text;
            message = subtitle ? subtitle : t('warning');
            break;
        case 'success':
            bgColor = 'bg-gradient-to-r from-green-400 via-green-500 to-green-600';
            title = subtitle ? text : t('success');
            message = subtitle ? subtitle : text;
            break;
        default:
            bgColor = 'bg-gradient-to-r from-green-400 via-green-500 to-green-600';
            break;
    }

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    className={`rounded-[0.4rem] cursor-pointer max-h-[50vh] top-3 md:top-3 lg:top-3 z-[103] overflow-auto no-scrollbar ${bgColor} ${alignLeft ? 'absolute w-[calc(100%-25px)] left-3' : 'left-[12px] lg:left-[333px] fixed w-[calc(100vw-24px)] lg:w-[calc(100vw-344px)]'} ${isVisible ? '' : 'pointer-events-none'}`}
                    initial={{ marginTop: '-500px' }}
                    animate={{ marginTop: '0px' }}
                    exit={{ marginTop: '-500px' }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    onClick={closePopup}
                >
                    <FontAwesomeIcon icon={faXmark} className={`text-white text-3xl absolute top-3 right-3`} />
                    <Title
                        className="mt-0 md:mt-0 lg:mt-0 text-white p-10"
                        subtitle={message}
                        icon={type === 'error' ? faXmarkCircle : type === 'warning' ? faTriangleExclamation : faCheckCircle}
                        text={title}
                    />
                    <motion.div
                        className="h-[4px] mb-[1.25%] ml-[1.25%] rounded-full bg-white absolute bottom-0 left-0"
                        initial={{ width: '97.5%' }}
                        animate={{ width: `${97.5 - progress}%` }}
                        transition={{ ease: 'linear' }}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default StatusPopups;
