import { MotionConfig } from 'framer-motion';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import DashboardWrapper from './DashboardWrapper';
import CardCreatePage from './Pages/Dashboard/Cards/CardCreatePage';
import CardEditPage from './Pages/Dashboard/Cards/CardEditPage';
import CardsImportPage from './Pages/Dashboard/Cards/CardsImportPage';
import CardsListPage from './Pages/Dashboard/Cards/CardsListPage';
import NotifyPage from './Pages/Dashboard/Notify/NotifyPage';
import SocialsPage from './Pages/Dashboard/Organisation/SocialsPage';
import ThemePage from './Pages/Dashboard/Organisation/ThemePage';
import UsersCreatePage from './Pages/Dashboard/Users/UsersCreatePage';
import UsersEditPage from './Pages/Dashboard/Users/UsersEditPage';
import UsersPage from './Pages/Dashboard/Users/UsersPage';
import Overview from './Pages/Dashboard/Overview/Overview';
import UserSettingsPage from './Pages/Dashboard/UserSettingsPage';
import LandingWrapper from './Pages/LandingWrapper';
import LoginPage from './Pages/Login/LoginPage';
import Page404 from './Pages/Page404';
import Modal from './Shared/Modal/Modal';
import PrivateRoute from './Router/PrivateRoute';
import PublicRoute from './Router/PublicRoute';
import OrganisationsList from './Pages/Dashboard/Admin/Organisation/OrganisationsList';
import ReleaseNoteList from './Pages/Dashboard/Admin/ReleaseNote/ReleaseNoteList';
import OrganisationCreatePage from './Pages/Dashboard/Admin/Organisation/OrganisationCreatePage';
import ReleaseNoteCreatePage from './Pages/Dashboard/Admin/ReleaseNote/ReleaseNoteCreatePage';
import OrganisationEditPage from './Pages/Dashboard/Admin/Organisation/OrganisationEditPage';
import ReleaseNoteEditPage from './Pages/Dashboard/Admin/ReleaseNote/ReleaseNoteEditPage';
import NewsPage from './Pages/Dashboard/News/NewsPage';
import WizardPage from './Pages/Dashboard/Wizard/WizardPage';
import SuccessPage from './Pages/Dashboard/Success/SuccessPage';
import ResetPasswordPage from './Pages/Login/ResetPasswordPage';
import ForgotPasswordPage from './Pages/Login/ForgotPasswordPage';
import SponsorAddPage from './Pages/Dashboard/Sponsor/SponsorAddPage';
import SponsorListPage from './Pages/Dashboard/Sponsor/SponsorListPage';
import AddNewsPage from "./Pages/Dashboard/News/AddNewsPage";
import AppBuilderPage from "./Pages/Dashboard/Organisation/AppBuilderPage";
import EditNewsPage from "./Pages/Dashboard/News/EditNewsPage";
import Mapping from './Pages/Dashboard/Mapper/Mapping';
import Preview from './Pages/Dashboard/Mapper/Preview';
import ChatBox from './Pages/PatrickAssistant/ChatBox';
import SuggestionList from './Pages/Dashboard/Admin/Suggestion/SuggestionList';
import SuggestionCreatePage from './Pages/Dashboard/Admin/Suggestion/SuggestionCreatePage';
import SuggestionEditPage from './Pages/Dashboard/Admin/Suggestion/SuggestionEditPage';
import { ChatProvider } from './Pages/PatrickAssistant/ChatContext';
import { JWT } from '../Utils/JWT';
import { MapperProvider } from './Pages/Dashboard/Mapper/Context/MapperProvider';
import { useAppSelector } from '../Redux/store';
import { store } from '../Redux/store';
import SponsorEditPage from "./Pages/Dashboard/Sponsor/SponsorEditPage";
import NotifyHistoryPage from './Pages/Dashboard/Notify/NotifyHistoryPage';

const AppContent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const defaultLang = 'nl';
    const is_new = useAppSelector((s) => s.organisation.settings?.new) || false;
    const roles = JWT.read<Array<string>>('roles') || [];

    const noAssistant = !location.pathname.startsWith(`/${i18n.language}/dashboard`) || ['/dashboard/cards/import/map', '/dashboard/cards/import/preview', '/dashboard/success', '/dashboard/wizard'].includes(location.pathname.replace(`/${i18n.language}`, ''));

    const [notificationAccess, setNotificationAccess] = useState(() => {
        const savedNotificationAccess = sessionStorage.getItem('hasNotificationAccess');
        return savedNotificationAccess ? savedNotificationAccess === 'true' : false;
    });

    const [sponsorAccess, setSponsorAccess] = useState(() => {
        const savedSponsorAccess = sessionStorage.getItem('hasSponsorAccess');
        return savedSponsorAccess ? savedSponsorAccess === 'true' : false;
    });

    const [patrickAssistantAccess, setPatrickAssistantAccess] = useState(() => {
        const savedPatrickAssistantAccess = sessionStorage.getItem('hasPatrickAssistantAccess');
        return savedPatrickAssistantAccess ? savedPatrickAssistantAccess === 'true' : false;
    });

    const handleSettingsUpdate = (notification: boolean, sponsors: boolean, patrickAssistant: boolean) => {
        setNotificationAccess(notification);
        setSponsorAccess(sponsors);
        setPatrickAssistantAccess(patrickAssistant);

        sessionStorage.setItem('hasNotificationAccess', String(notification));
        sessionStorage.setItem('hasSponsorAccess', String(sponsors));
        sessionStorage.setItem('hasPatrickAssistantAccess', String(patrickAssistant));
    };

    const isAdmin = roles.includes('ROLE_SUPER_ADMIN');
    const isSocialUser = roles.includes('ROLE_SOCIAL');

    useEffect(() => {
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];
        const currentLang = window.location.pathname.split('/')[1].toLowerCase();
        const storedLang = localStorage.getItem('preferredLanguage');

        if (validLanguages.includes(currentLang)) {
            if (currentLang !== i18n.language) {
                i18n.changeLanguage(currentLang).then(() => {
                    localStorage.setItem('preferredLanguage', currentLang);
                });
            }
        } else if (storedLang && validLanguages.includes(storedLang) && storedLang !== i18n.language && storedLang !== 'null' && storedLang !== null && storedLang !== undefined) {
            i18n.changeLanguage(storedLang).then(() => {
                navigate(`/${storedLang}${window.location.pathname}`);
            });
        } else {
            if (!storedLang && storedLang !== 'null' && storedLang !== null && storedLang !== undefined) {
                i18n.changeLanguage(storedLang as string).then(() => {
                    navigate(`/${storedLang}${window.location.pathname}`);
                });
            } else {
                i18n.changeLanguage('nl').then(() => {
                    navigate(`/nl${window.location.pathname}`);
                });
            }
        }
    }, [i18n]);

    return (
        <MotionConfig reducedMotion='user' transition={{ ease: [0.34, 1.56, 0.64, 1], duration: 0.4 }}>
            <div className='m-0 flex overflow-hidden' style={{ background: 'rgb(244, 248, 251)' }}>
                <ChatProvider>
                    <Modal>
                        <Routes>
                            <Route path='/:lang/dashboard' element={<PrivateRoute><DashboardWrapper onSettingsUpdate={handleSettingsUpdate} /></PrivateRoute>}>
                                {!isSocialUser && (
                                    <>
                                        <Route path='wizard' element={<WizardPage />} />
                                        <Route path='success' element={<SuccessPage />} />
                                    </>
                                )}

                                <Route index element={<Overview />} />

                                <Route path='organisation'>
                                    <Route path='appbuilder' element={<AppBuilderPage />} />
                                    <Route path='news' element={<NewsPage />} />
                                    <Route path='socials' element={<SocialsPage />} />
                                    <Route path='theme' element={<ThemePage />} />
                                </Route>
                                <Route path='news' >
                                    <Route index element={<NewsPage />} />
                                    <Route path='list' element={<NewsPage />} />
                                    <Route path='create' element={<AddNewsPage />} />
                                    <Route path='edit/:id' element={<EditNewsPage />} />
                                </Route>

                                {!isSocialUser && (
                                    <Route path='cards'>
                                        <Route index element={<CardsListPage />} />
                                        <Route path='list' element={<CardsListPage />} />
                                        <Route path='import'>
                                            <Route index element={<CardsImportPage />} />
                                            {patrickAssistantAccess && (
                                                <>
                                                    <Route path='map' element={<MapperProvider><Mapping /></MapperProvider>} />
                                                    <Route path='preview' element={<Preview />} />
                                                </>
                                            )}
                                        </Route>
                                        <Route path='create' element={<CardCreatePage />} />
                                        <Route path='edit/:id' element={<CardEditPage />} />
                                    </Route>
                                )}

                                {notificationAccess && (
                                    <Route path='notify'>
                                        <Route index element={<NotifyPage />} />
                                        <Route path='history' element={<NotifyHistoryPage />} />
                                    </Route>
                                )}

                                {!isSocialUser && (
                                    <Route path='users'>
                                        <Route index element={<UsersPage />} />
                                        <Route path='list' element={<UsersPage />} />
                                        <Route path='create' element={<UsersCreatePage />} />
                                        <Route path='edit/:id' element={<UsersEditPage />} />
                                    </Route>
                                )}

                                {sponsorAccess && (
                                    <Route path='sponsor'>
                                        <Route path='list' element={<SponsorListPage />} />
                                        <Route path='create' element={<SponsorAddPage />} />
                                        <Route path='edit/:id' element={<SponsorEditPage />} />
                                    </Route>
                                )}

                                {isAdmin && (
                                    <Route path='admin'>
                                        <Route index element={<OrganisationsList />} />
                                        <Route path='organisation'>
                                            <Route index element={<OrganisationsList />} />
                                            <Route path='list' element={<OrganisationsList />} />
                                            <Route path='create' element={<OrganisationCreatePage />} />
                                            <Route path='edit/:id' element={<OrganisationEditPage />} />
                                        </Route>
                                        <Route path='notes'>
                                            <Route index element={<ReleaseNoteList />} />
                                            <Route path='list' element={<ReleaseNoteList />} />
                                            <Route path='create' element={<ReleaseNoteCreatePage />} />
                                            <Route path='edit/:id' element={<ReleaseNoteEditPage />} />
                                        </Route>
                                        <Route path="suggestion">
                                            <Route index element={<SuggestionList />} />
                                            <Route path="list" element={<SuggestionList />} />
                                            <Route path="create" element={<SuggestionCreatePage />} />
                                            <Route path="edit/:id" element={<SuggestionEditPage />} />
                                        </Route>
                                    </Route>
                                )}

                                {/* <Route path='settings' element={<UserSettingsPage />} /> */}
                                <Route path='*' element={<Page404 />} />
                            </Route>

                            <Route path='/:lang/' element={<PublicRoute><LandingWrapper /></PublicRoute>}>
                                <Route index element={<LoginPage />} />
                                <Route path='login' element={<LoginPage />} />
                                <Route path='forgotpassword' element={<ForgotPasswordPage />} />
                                <Route path='resetpassword' element={<ResetPasswordPage />} />
                            </Route>

                            <Route path='/' element={<Navigate to={`/${localStorage.getItem('preferredLanguage') || defaultLang}/login`} replace />} />
                            <Route path='*' element={<Page404 fullHeight />} />
                        </Routes>
                        {(roles.includes('ROLE_SUPER_ADMIN') || !is_new) && !noAssistant && patrickAssistantAccess && <ChatBox />}
                    </Modal>
                </ChatProvider>
            </div>
        </MotionConfig>
    );
};

const App = () => (
    <Provider store={store}>
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    </Provider>
);

export default App;
