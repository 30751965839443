import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useGetSuggestionsQuery, useDeleteSuggestionMutation } from '../../../../../Redux/Api/Suggestion';
import Button from '../../../../Shared/Form/Button'
import Column from '../../../../Shared/Table/Column'
import Data from '../../../../Shared/Table/Data'
import Header from '../../../../Shared/Table/Header'
import Row from '../../../../Shared/Table/Row'
import Table from '../../../../Shared/Table/Table'
import Title from '../../../../Shared/Title'
import LoadingPage from '../../../LoadingPage'
import { Hydra } from '../../../../../Utils/Hydra'
import BusyArea from '../../../../Shared/BusyArea'
import { useMediaQuery } from "@mui/material";

const SuggestionList = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [deleteSuggestion] = useDeleteSuggestionMutation()
    const { data: Suggestion, refetch } = useGetSuggestionsQuery();

    const isSmallScreen = useMediaQuery('(min-width: 600px)');

    useEffect(() => {
        refetch();
    }, []);

    const deleteSuggestionAction = async (id: string) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteSuggestion(id)
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
        refetch()
    }

    if (!Suggestion) return <LoadingPage />

    const textKey = `text_${i18n.language}`;

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col lg:flex-row justify-between items-start lg:items-center'>
                    <Title text={t('patrickai:suggestion')} textNotBold />
                    <Button action={() => navigate(`/${i18n.language}/dashboard/admin/suggestion/create`)} className='my-6' title={t('patrickai:createSuggestion')} icon={faPlus} iconright />
                </div>
                <Table withSearch withEdit withDelete>
                    <Header>
                        <Column hidden={!isSmallScreen} name={t('patrickai:pathTitle')} />
                        <Column name={t('patrickai:suggestionTitle')} />
                    </Header>
                    <Data>
                        {Suggestion.map((note, i) => (
                            <Row
                                firstButton='editUrl'
                                lastButton='deleteAction'
                                key={i}
                                index={i}
                                editUrl={`/${i18n.language}/dashboard/admin/suggestion/edit/` + note.id}
                                data={isSmallScreen ?
                                    [note.title, (note.text as { [key: string]: string })[textKey]]
                                    :
                                    [(note.text as { [key: string]: string })[textKey]]
                                }
                                deleteAction={() => deleteSuggestionAction(note.id)} />
                        ))}
                    </Data>
                </Table>
            </BusyArea>
        </>
    )
}

export default SuggestionList
